import GenericTemplate from "../templates/GenericTemplate";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import { ThemeProvider } from "@material-ui/styles";
import Box from "@material-ui/core/Box";
import { createMuiTheme } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";

import moment from "moment";

import DatePicker, { registerLocale } from "react-datepicker";
import ja from "date-fns/locale/ja";

import React, { useEffect, useState } from "react";
import { API, graphqlOperation } from "aws-amplify";
import {
  listProfitAndLossStatements,
  listProfitAndLossStatementsByUser,
} from "../../graphql/queries";
import {
  createProfitAndLossStatement,
  updateProfitAndLossStatement,
  deleteProfitAndLossStatement,
  updateUser,
} from "../../graphql/mutations";
import {
  onCreateProfitAndLossStatement,
  onDeleteProfitAndLossStatement,
  onUpdateProfitAndLossStatement,
} from "../../graphql/subscriptions";
import {
  ListProfitAndLossStatementsQuery,
  ListProfitAndLossStatementsByUserQuery,
  OnCreateProfitAndLossStatementSubscription,
  OnDeleteProfitAndLossStatementSubscription,
  CreateProfitAndLossStatementMutationVariables,
  UpdateProfitAndLossStatementMutationVariables,
  DeleteProfitAndLossStatementMutationVariables,
  OnUpdateProfitAndLossStatementSubscription,
  UpdateUserMutationVariables,
} from "../../API";

import { DataGrid } from "@material-ui/data-grid";

import { getUserId } from "../../util";

import { useUser, User } from "../../util/user";

const theme = createMuiTheme({
  palette: {
    primary: { main: "#00838f" },
    secondary: { main: "#e0f7fa" },
  },
});

const onClickDeleteProfitAndLossStatement = (id: string) => {
  const deletedProfitAndLossStatement: DeleteProfitAndLossStatementMutationVariables =
    {
      input: {
        id: id,
      },
    };
  API.graphql(
    graphqlOperation(
      deleteProfitAndLossStatement,
      deletedProfitAndLossStatement
    )
  );
};

const columns = [
  // { field: 'id', headerName: 'id', width: 90, editable: false },
  //   { field: "since", headerName: "日時自", width: 180, editable: true },
  //   { field: "to", headerName: "日時至", width: 180, editable: true },
  { field: "account", headerName: "科目", width: 180, editable: true },
  { field: "debit", headerName: "金額", width: 180, editable: true },
  // { field: "credit", headerName: "貸方", width: 180, editable: true },
  // {
  //     field: 'createdAt',
  //     headerName: '追加日',
  //     type: 'date',
  //     width: 240,
  //     editable: false,
  // },
  {
    field: "",
    headerName: "",
    width: 85,
    disableClickEventBubbling: true,
    renderCell: (params: any) => {
      const onClick = () => {
        onClickDeleteProfitAndLossStatement(params.id);
        return;
      };

      return (
        <Button
          variant="contained"
          color="secondary"
          name="commit"
          onClick={onClick}
        >
          削除
        </Button>
      );
    },
  },
];

type FormState = {
  id: string;
  account?: string | null;
  debit?: number | null;
  credit?: number | null;
  user_id: string;
};

type ProfitAndLossStatement = FormState & {
  createdAt: string;
  updatedAt: string;
};

type ProfitAndLossStatementSubscriptionEventCreate = {
  value: { data: OnCreateProfitAndLossStatementSubscription };
};
type ProfitAndLossStatementSubscriptionEventDelete = {
  value: { data: OnDeleteProfitAndLossStatementSubscription };
};
type ProfitAndLossStatementSubscriptionEventUpdate = {
  value: { data: OnUpdateProfitAndLossStatementSubscription };
};

const useProfitAndLossStatements = () => {
  const [ProfitAndLossStatements, setProfitAndLossStatements] = useState<
    ProfitAndLossStatement[]
  >([]);

  useEffect(() => {
    (async () => {
      let nextToken;
      let items: ProfitAndLossStatement[] = [];
      while (true) {
        const result = await API.graphql(
          graphqlOperation(listProfitAndLossStatementsByUser, {
            user_id: getUserId(),
            nextToken: nextToken,
          })
        );
        if ("data" in result && result.data) {
          const newJournals =
            result.data as ListProfitAndLossStatementsByUserQuery;

          if (newJournals.listProfitAndLossStatementsByUser) {
            const tempItems = newJournals.listProfitAndLossStatementsByUser
              .items as ProfitAndLossStatement[];
            items = items.concat(tempItems);
          }
          if (newJournals.listProfitAndLossStatementsByUser?.nextToken) {
            nextToken =
              newJournals.listProfitAndLossStatementsByUser?.nextToken;
          } else {
            setProfitAndLossStatements(items as ProfitAndLossStatement[]);
            break;
          }
        }
      }

      const clientCreateProfitAndLossStatement = API.graphql(
        graphqlOperation(onCreateProfitAndLossStatement, {
          owner: getUserId(),
        })
      );
      if ("subscribe" in clientCreateProfitAndLossStatement) {
        clientCreateProfitAndLossStatement.subscribe({
          next: ({
            value: { data },
          }: ProfitAndLossStatementSubscriptionEventCreate) => {
            if (data.onCreateProfitAndLossStatement) {
              const profitAndLossStatement: ProfitAndLossStatement =
                data.onCreateProfitAndLossStatement;
              setProfitAndLossStatements((prev) => [
                ...prev,
                profitAndLossStatement,
              ]);
            }
          },
        });
      }

      const clientDeleteProfitAndLossStatement = API.graphql(
        graphqlOperation(onDeleteProfitAndLossStatement, {
          owner: getUserId(),
        })
      );
      if ("subscribe" in clientDeleteProfitAndLossStatement) {
        clientDeleteProfitAndLossStatement.subscribe({
          next: ({
            value: { data },
          }: ProfitAndLossStatementSubscriptionEventDelete) => {
            if (data.onDeleteProfitAndLossStatement) {
              const profitAndLossStatement: ProfitAndLossStatement =
                data.onDeleteProfitAndLossStatement;
              setProfitAndLossStatements((prev) => {
                return prev.filter(
                  (obj) => obj.id !== profitAndLossStatement.id
                );
              });
            }
          },
        });
      }

      const clientUpdateProfitAndLossStatement = API.graphql(
        graphqlOperation(onUpdateProfitAndLossStatement, {
          owner: getUserId(),
        })
      );
      if ("subscribe" in clientUpdateProfitAndLossStatement) {
        clientUpdateProfitAndLossStatement.subscribe({
          next: ({
            value: { data },
          }: ProfitAndLossStatementSubscriptionEventUpdate) => {
            if (data.onUpdateProfitAndLossStatement) {
              const profitAndLossStatement: ProfitAndLossStatement =
                data.onUpdateProfitAndLossStatement;
              setProfitAndLossStatements((prev) => [
                ...prev.filter((obj) => obj.id !== profitAndLossStatement.id),
                profitAndLossStatement,
              ]);
            }
          },
        });
      }
    })();
  }, []);

  return ProfitAndLossStatements;
};

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
});

const ProductPage: React.FC = () => {
  const [input, setInput] = useState<FormState>({
    id: "",
    account: "",
    debit: 0,
    credit: 0,
    user_id: getUserId(),
  });
  const profitAndLossStatements = useProfitAndLossStatements();

  const [user, setUser] = useUser();

  const [filterNosummary, setFilterNosummary] = useState<boolean>();

  const onFormChange = ({
    target: { name, value },
  }: React.ChangeEvent<HTMLInputElement>) => {
    setInput((prev) => ({ ...prev, [name]: value }));
  };

  const onProfitAndLossStatement = () => {
    // if (input.since === "") return;
    const newProfitAndLossStatement: CreateProfitAndLossStatementMutationVariables =
      {
        input: {
          account: input.account ? String(input.account) : "",
          debit: input.debit ? Number(input.debit) : 0,
          credit: input.credit ? Number(input.credit) : 0,
          user_id: getUserId(),
        },
      };
    setInput({
      id: "",
      account: "",
      debit: 0,
      credit: 0,
      user_id: getUserId(),
    });
    API.graphql(
      graphqlOperation(createProfitAndLossStatement, newProfitAndLossStatement)
    );
  };

  const onEditCellChangeCommitted = (e: any) => {
    console.log(e.field);
    const id = e.id;
    let input;
    if (e.field == "account") {
      input = {
        id: id,
        account: e.props.value ? String(e.props.value) : "",
      };
    } else if (e.field == "debit") {
      input = {
        id: id,
        debit: e.props.value ? Number(e.props.value) : 0,
      };
    } else if (e.field == "credit") {
      input = {
        id: id,
        credit: e.props.value ? Number(e.props.value) : 0,
      };
    } else {
      return;
    }
    console.log(input);

    const updatedProfitAndLossStatement: UpdateProfitAndLossStatementMutationVariables =
      {
        input: input,
      };
    API.graphql(
      graphqlOperation(
        updateProfitAndLossStatement,
        updatedProfitAndLossStatement
      )
    );
  };

  const Today = new Date();
  const [dateSince, setDateSince] = React.useState(Today);
  const [dateTo, setDateTo] = React.useState(Today);
  registerLocale("ja", ja);

  const onChangeDateSince = (selectedDate: Date) => {
    //   console.log(selectedDate);
    // selectedDate || Today
    setUser((prevState: User) => {
      let obj = Object.assign({}, prevState);
      obj.datetime_since_profit_and_loss_statement =
        moment(selectedDate).format();
      return obj;
    });
    const updatedUser: UpdateUserMutationVariables = {
      input: {
        id: getUserId(),
        datetime_since_profit_and_loss_statement: moment(selectedDate).format(),
      },
    };
    API.graphql(graphqlOperation(updateUser, updatedUser));
  };
  const onChangeDateTo = (selectedDate: Date) => {
    //   console.log(selectedDate);
    // selectedDate || Today
    setUser((prevState: User) => {
      let obj = Object.assign({}, prevState);
      obj.datetime_to_profit_and_loss_statement = moment(selectedDate).format();
      return obj;
    });
    const updatedUser: UpdateUserMutationVariables = {
      input: {
        id: getUserId(),
        datetime_to_profit_and_loss_statement: moment(selectedDate).format(),
      },
    };
    API.graphql(graphqlOperation(updateUser, updatedUser));
  };

  return (
    <GenericTemplate title="損益計算書">
      <ThemeProvider theme={theme}>
        <Box>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div style={{ margin: "0 auto 10px 0" }}>
              <Grid
                container
                spacing={0}
                justify="flex-start"
                style={{ paddingBottom: "10px" }}
              >
                <Grid item xs={6} md={6}>
                  {" "}
                  <div>
                    <label>自</label>
                    <DatePicker
                      //   className="customDatePicker"
                      dateFormat="yyyy/MM/dd"
                      locale="ja"
                      selected={
                        user.datetime_since_profit_and_loss_statement
                          ? moment(
                              user.datetime_since_profit_and_loss_statement
                            ).toDate()
                          : undefined
                      }
                      //   selected={new Date()}
                      // minDate={Today}
                      // onChange={onFormChange}
                      onChange={onChangeDateSince}
                    />
                  </div>
                </Grid>
                <Grid item xs={6} md={6}>
                  {" "}
                  <div>
                    <label>至</label>
                    <DatePicker
                      //   className="customDatePicker"
                      dateFormat="yyyy/MM/dd"
                      locale="ja"
                      selected={
                        user.datetime_to_profit_and_loss_statement
                          ? moment(
                              user.datetime_to_profit_and_loss_statement
                            ).toDate()
                          : undefined
                      }
                      //   selected={new Date()}
                      // minDate={Today}
                      // onChange={onFormChange}
                      onChange={onChangeDateTo}
                    />
                  </div>
                </Grid>
              </Grid>
            </div>
            <div style={{ margin: "auto 0 20px auto" }}>
              <TextField
                id="name"
                type="text"
                // label='表示名'
                style={{ width: 500 }}
                value={user.name}
              >
                {user.name}
              </TextField>
            </div>
          </div>
        </Box>

        <Box p={2} bgcolor="rgb(92, 44, 143);" color="primary.contrastText">
          新規登録 　{" "}
        </Box>

        <Box p={2} bgcolor="secondary.main" color="rgb(92, 44, 143);">
          <form action="/users" acceptCharset="UTF-8" method="post">
            {/* <div>
              <DatePicker
                className="customDatePicker"
                dateFormat="yyyy/MM/dd"
                locale="ja"
                selected={dateSince}
                // minDate={Today}
                // onChange={onFormChange}
                onChange={(selectedDate) => {
                  //   console.log(selectedDate);
                  setDateSince(selectedDate || Today);
                }}
              />
            </div>
            <div>
              <DatePicker
                className="customDatePicker"
                dateFormat="yyyy/MM/dd"
                locale="ja"
                selected={dateTo}
                // minDate={Today}
                // onChange={onFormChange}
                onChange={(selectedDate) => {
                  //   console.log(selectedDate);
                  setDateTo(selectedDate || Today);
                }}
              />
            </div> */}
            <div>
              <TextField
                id="account"
                type="text"
                name="account"
                label="科目"
                style={{ width: 500 }}
                value={input.account}
                onChange={onFormChange}
              />
            </div>
            <div>
              <TextField
                id="debit"
                type="text"
                name="debit"
                label="金額"
                style={{ width: 500 }}
                value={input.debit}
                onChange={onFormChange}
              />
            </div>
            {/* <div>
              <TextField
                id="credit"
                type="text"
                name="credit"
                label="貸方"
                style={{ width: 500 }}
                value={input.credit}
                onChange={onFormChange}
              />
            </div> */}
            <Button
              style={{ margin: "10px auto 10px auto" }}
              variant="contained"
              color="inherit"
              name="commit"
              onClick={onProfitAndLossStatement}
            >
              出力
            </Button>
          </form>
        </Box>
      </ThemeProvider>

      <div style={{ height: 700, width: "100%" }}>
        <DataGrid
          rows={profitAndLossStatements}
          columns={columns}
          onEditCellChangeCommitted={onEditCellChangeCommitted}
          // checkboxSelection
          sortModel={[
            {
              field: "account",
              sort: "asc",
            },
          ]}
        />
      </div>
    </GenericTemplate>
  );
};

export default ProductPage;
