import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

// import { Amplify } from "aws-amplify";

import { Authenticator } from "@aws-amplify/ui-react";
import "@aws-amplify/ui-react/styles.css";

// import SimpleTodoPage from "./components/pages/SimpleTodoPage";
import JournalPage from "./components/pages/JournalPage";
import CashRegisterPage from "./components/pages/CashRegisterPage";
import GeneralLedgerPage from "./components/pages/GeneralLedgerPage";
import TrialBalancePage from "./components/pages/TrialBalancePage";
import BalanceSheet from "./components/pages/BalanceSheet";
import ProfitAndLossStatement from "./components/pages/ProfitAndLossStatement";
import CashFlowStatement from "./components/pages/CashFlowStatement";
import InvoicePage from "./components/pages/InvoicePage";
import ItemPage from "./components/pages/ItemPage";
import UserSettingsPage from "./components/pages/UserSettingsPage";
import ExportedInvoicePage from "./components/pages/ExportedInvoicePage";

// Amplify.configure({
//   aws_project_region: process.env.REACT_APP_AWS_PROJECT_REGION,
//   aws_cognito_region: process.env.REACT_APP_AWS_COGNITO_REGION,
//   aws_user_pools_id: process.env.REACT_APP_AWS_USER_POOLS_ID,
//   aws_user_pools_web_client_id: process.env.REACT_APP_AWS_USER_POOLS_CLIENT_ID,
// });

const App: React.FC = () => {
  return (
    <Authenticator hideSignUp={false} loginMechanisms={["email"]}>
      {({ signOut, user }) => (
        <main>
          {/* <h1>Hello {user?.username}</h1>
          <button onClick={signOut}>Sign out</button> */}

          <Router>
            <Switch>
              {/* <Route path="/simple-todos" component={SimpleTodoPage} exact /> */}
              {/* <Route path="/full-todos" component={JournalPage} exact /> */}
              <Route path="/" component={JournalPage} exact />
              <Route path="/journal" component={JournalPage} exact />
              <Route path="/cash-register" component={CashRegisterPage} exact />
              <Route
                path="/general-ledger"
                component={GeneralLedgerPage}
                exact
              />
              <Route path="/trial-balance" component={TrialBalancePage} exact />
              <Route path="/balance-sheet" component={BalanceSheet} exact />
              <Route
                path="/profit-and-loss-statement"
                component={ProfitAndLossStatement}
                exact
              />
              <Route
                path="/cash-flow-statement"
                component={CashFlowStatement}
                exact
              />
              <Route
                path="/exported-invoice/:id"
                component={ExportedInvoicePage}
                exact
              />
              <Route
                path="/exported-invoice/new"
                component={ExportedInvoicePage}
                exact
              />
              <Route path="/invoice" component={InvoicePage} exact />
              <Route path="/invoice/:id/item" component={ItemPage} exact />
              <Route path="/settings" component={UserSettingsPage} exact />
            </Switch>
          </Router>
        </main>
      )}
    </Authenticator>
  );
};

export default App;
