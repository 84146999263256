import GenericTemplate from "../templates/GenericTemplate";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import { ThemeProvider } from "@material-ui/styles";
import Box from "@material-ui/core/Box";
import { createMuiTheme } from "@material-ui/core/styles";

import moment from "moment";
import DatePicker, { registerLocale } from "react-datepicker";
import ja from "date-fns/locale/ja";

import React, { useEffect, useState } from "react";
import { API, graphqlOperation } from "aws-amplify";
import {
  listTrialBalances,
  listTrialsBalancesByUser,
} from "../../graphql/queries";
import {
  createTrialBalance,
  updateTrialBalance,
  deleteTrialBalance,
  updateUser,
} from "../../graphql/mutations";
import {
  onCreateTrialBalance,
  onDeleteTrialBalance,
  onUpdateTrialBalance,
} from "../../graphql/subscriptions";
import {
  ListTrialBalancesQuery,
  ListTrialsBalancesByUserQuery,
  OnCreateTrialBalanceSubscription,
  OnDeleteTrialBalanceSubscription,
  CreateTrialBalanceMutationVariables,
  UpdateTrialBalanceMutationVariables,
  DeleteTrialBalanceMutationVariables,
  OnUpdateTrialBalanceSubscription,
  UpdateUserMutationVariables,
} from "../../API";

import { DataGrid } from "@material-ui/data-grid";

import { getUserId } from "../../util";

import { useUser, User } from "../../util/user";

const theme = createMuiTheme({
  palette: {
    primary: { main: "#00838f" },
    secondary: { main: "#e0f7fa" },
  },
});

const onClickDeleteTrialBalance = (id: string) => {
  const deletedTrialBalance: DeleteTrialBalanceMutationVariables = {
    input: {
      id: id,
    },
  };
  API.graphql(graphqlOperation(deleteTrialBalance, deletedTrialBalance));
};

const columns = [
  // { field: 'id', headerName: 'id', width: 90, editable: false },
  // { field: 'datetime', headerName: '日時', width: 240, editable: true },
  { field: "debit", headerName: "借方", width: 180, editable: true },
  { field: "account", headerName: "勘定科目", width: 180, editable: true },
  { field: "credit", headerName: "貸方", width: 180, editable: true },
  // {
  //     field: 'createdAt',
  //     headerName: '追加日',
  //     type: 'date',
  //     width: 240,
  //     editable: false,
  // },
  {
    field: "",
    headerName: "",
    width: 85,
    disableClickEventBubbling: true,
    renderCell: (params: any) => {
      const onClick = () => {
        onClickDeleteTrialBalance(params.id);
        return;
      };

      return (
        <Button
          variant="contained"
          color="secondary"
          name="commit"
          onClick={onClick}
        >
          削除
        </Button>
      );
    },
  },
];

type FormState = {
  id: string;
  debit?: number | null;
  account?: string | null;
  credit?: number | null;
  user_id: string;
};

type TrialBalance = FormState & {
  createdAt: string;
  updatedAt: string;
};

type TrialBalanceSubscriptionEventCreate = {
  value: { data: OnCreateTrialBalanceSubscription };
};
type TrialBalanceSubscriptionEventDelete = {
  value: { data: OnDeleteTrialBalanceSubscription };
};
type TrialBalanceSubscriptionEventUpdate = {
  value: { data: OnUpdateTrialBalanceSubscription };
};

const useTrialBalances = () => {
  const [TrialBalances, setTrialBalances] = useState<TrialBalance[]>([]);

  useEffect(() => {
    (async () => {
      let nextToken;
      let items: TrialBalance[] = [];
      while (true) {
        const result = await API.graphql(
          graphqlOperation(listTrialsBalancesByUser, {
            user_id: getUserId(),
            nextToken: nextToken,
          })
        );
        if ("data" in result && result.data) {
          const newJournals = result.data as ListTrialsBalancesByUserQuery;

          if (newJournals.listTrialsBalancesByUser) {
            const tempItems = newJournals.listTrialsBalancesByUser
              .items as TrialBalance[];
            items = items.concat(tempItems);
          }
          if (newJournals.listTrialsBalancesByUser?.nextToken) {
            nextToken = newJournals.listTrialsBalancesByUser?.nextToken;
          } else {
            setTrialBalances(items as TrialBalance[]);
            break;
          }
        }
      }

      const clientCreateTrialBalance = API.graphql(
        graphqlOperation(onCreateTrialBalance, {
          owner: getUserId(),
        })
      );
      if ("subscribe" in clientCreateTrialBalance) {
        clientCreateTrialBalance.subscribe({
          next: ({ value: { data } }: TrialBalanceSubscriptionEventCreate) => {
            if (data.onCreateTrialBalance) {
              const trialBalance: TrialBalance = data.onCreateTrialBalance;
              setTrialBalances((prev) => [...prev, trialBalance]);
            }
          },
        });
      }

      const clientDeleteTrialBalance = API.graphql(
        graphqlOperation(onDeleteTrialBalance, {
          owner: getUserId(),
        })
      );
      if ("subscribe" in clientDeleteTrialBalance) {
        clientDeleteTrialBalance.subscribe({
          next: ({ value: { data } }: TrialBalanceSubscriptionEventDelete) => {
            if (data.onDeleteTrialBalance) {
              const trialBalance: TrialBalance = data.onDeleteTrialBalance;
              setTrialBalances((prev) => {
                return prev.filter((obj) => obj.id !== trialBalance.id);
              });
            }
          },
        });
      }

      const clientUpdateTrialBalance = API.graphql(
        graphqlOperation(onUpdateTrialBalance, {
          owner: getUserId(),
        })
      );
      if ("subscribe" in clientUpdateTrialBalance) {
        clientUpdateTrialBalance.subscribe({
          next: ({ value: { data } }: TrialBalanceSubscriptionEventUpdate) => {
            if (data.onUpdateTrialBalance) {
              const trialBalance: TrialBalance = data.onUpdateTrialBalance;
              setTrialBalances((prev) => [
                ...prev.filter((obj) => obj.id !== trialBalance.id),
                trialBalance,
              ]);
            }
          },
        });
      }
    })();
  }, []);

  return TrialBalances;
};

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
});

const ProductPage: React.FC = () => {
  const [input, setInput] = useState<FormState>({
    id: "",
    debit: 0,
    account: "",
    credit: 0,
    user_id: getUserId(),
  });
  const trialBalances = useTrialBalances();
  const [user, setUser] = useUser();

  const [filterNosummary, setFilterNosummary] = useState<boolean>();

  const onFormChange = ({
    target: { name, value },
  }: React.ChangeEvent<HTMLInputElement>) => {
    setInput((prev) => ({ ...prev, [name]: value }));
  };

  const onTrialBalance = () => {
    // if (input.datetime === "") return;
    const newTrialBalance: CreateTrialBalanceMutationVariables = {
      input: {
        debit: input.debit ? Number(input.debit) : 0,
        account: input.account ? String(input.account) : "",
        credit: input.credit ? Number(input.credit) : 0,
        user_id: getUserId(),
      },
    };
    setInput({
      id: "",
      debit: 0,
      account: "",
      credit: 0,
      user_id: getUserId(),
    });
    API.graphql(graphqlOperation(createTrialBalance, newTrialBalance));
  };

  const onEditCellChangeCommitted = (e: any) => {
    console.log(e.field);
    const id = e.id;
    let input;
    if (e.field == "debit") {
      input = {
        id: id,
        debit: e.props.value ? Number(e.props.value) : 0,
      };
    } else if (e.field == "account") {
      input = {
        id: id,
        account: e.props.value ? String(e.props.value) : "",
      };
    } else if (e.field == "credit") {
      input = {
        id: id,
        credit: e.props.value ? Number(e.props.value) : 0,
      };
    } else {
      return;
    }
    console.log(input);

    const updatedTrialBalance: UpdateTrialBalanceMutationVariables = {
      input: input,
    };
    API.graphql(graphqlOperation(updateTrialBalance, updatedTrialBalance));
  };

  const onChangeDate = (selectedDate: Date) => {
    //   console.log(selectedDate);
    // selectedDate || Today
    setUser((prevState: User) => {
      let jasper = Object.assign({}, prevState);
      jasper.datetime_trial_balance = moment(selectedDate).format();
      return jasper;
    });
    const updatedUser: UpdateUserMutationVariables = {
      input: {
        id: getUserId(),
        datetime_trial_balance: moment(selectedDate).format(),
      },
    };
    API.graphql(graphqlOperation(updateUser, updatedUser));
  };

  return (
    <GenericTemplate title="残高試算表">
      <ThemeProvider theme={theme}>
        <Box>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div style={{ margin: "0 auto 10px 0" }}>
              <DatePicker
                //   className="customDatePicker"
                dateFormat="yyyy/MM/dd"
                locale="ja"
                selected={
                  user.datetime_trial_balance
                    ? moment(user.datetime_trial_balance).toDate()
                    : undefined
                }
                //   selected={new Date()}
                // minDate={Today}
                // onChange={onFormChange}
                onChange={onChangeDate}
              />
            </div>
            <div style={{ margin: "0 0 10px auto" }}>
              <TextField
                id="name"
                type="text"
                // label='表示名'
                style={{ width: 500 }}
                value={user.name}
              >
                {user.name}
              </TextField>
            </div>
          </div>
        </Box>

        <Box p={2} bgcolor="rgb(92, 44, 143);" color="primary.contrastText">
          新規登録 　{" "}
        </Box>

        <Box p={2} bgcolor="secondary.main" color="rgb(92, 44, 143);">
          <form action="/users" acceptCharset="UTF-8" method="post">
            {/* <div>
              <TextField
                id="datetime"
                type="text"
                name="datetime"
                label="日時"
                style={{ width: 500 }}
                value={input.datetime}
                onChange={onFormChange}
              />
            </div> */}
            <div>
              <TextField
                id="debit"
                type="text"
                name="debit"
                label="借方"
                style={{ width: 500 }}
                value={input.debit}
                onChange={onFormChange}
              />
            </div>
            <div>
              <TextField
                id="account"
                type="text"
                name="account"
                label="勘定科目"
                style={{ width: 500 }}
                value={input.account}
                onChange={onFormChange}
              />
            </div>
            <div>
              <TextField
                id="credit"
                type="text"
                name="credit"
                label="貸方"
                style={{ width: 500 }}
                value={input.credit}
                onChange={onFormChange}
              />
            </div>
            <Button
              style={{ margin: "10px auto 10px auto" }}
              variant="contained"
              color="inherit"
              name="commit"
              onClick={onTrialBalance}
            >
              出力
            </Button>
          </form>
        </Box>
      </ThemeProvider>

      <div style={{ height: 700, width: "100%" }}>
        <DataGrid
          rows={trialBalances}
          columns={columns}
          onEditCellChangeCommitted={onEditCellChangeCommitted}
          // checkboxSelection
          sortModel={[
            {
              field: "account",
              sort: "asc",
            },
          ]}
        />
      </div>
    </GenericTemplate>
  );
};

export default ProductPage;
