import GenericTemplate from "../templates/GenericTemplate";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import { ThemeProvider } from "@material-ui/styles";
import Box from "@material-ui/core/Box";
import { Link } from "react-router-dom";
import { createMuiTheme } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";

import moment from "moment";
import DatePicker, { registerLocale } from "react-datepicker";
import ja from "date-fns/locale/ja";

import React, { useEffect, useState } from "react";
import { API, graphqlOperation } from "aws-amplify";
import {
  listInvoices,
  getUser,
  listInvoicesByUser,
} from "../../graphql/queries";
import {
  createUser,
  createInvoice,
  updateInvoice,
  deleteInvoice,
  updateUser,
} from "../../graphql/mutations";
import {
  onCreateInvoice,
  onDeleteInvoice,
  onUpdateInvoice,
} from "../../graphql/subscriptions";
import {
  GetUserQuery,
  GetUserQueryVariables,
  CreateUserMutationVariables,
  UpdateUserMutationVariables,
  ListInvoicesQuery,
  ListInvoicesByUserQuery,
  ListInvoicesByUserQueryVariables,
  OnCreateInvoiceSubscription,
  OnDeleteInvoiceSubscription,
  CreateInvoiceMutationVariables,
  UpdateInvoiceMutationVariables,
  DeleteInvoiceMutationVariables,
  OnUpdateInvoiceSubscription,
} from "../../API";

import { DataGrid } from "@material-ui/data-grid";

import { getUserId } from "../../util";

import { useUser, User } from "../../util/user";

const theme = createMuiTheme({
  palette: {
    primary: { main: "#00838f" },
    secondary: { main: "#e0f7fa" },
  },
});

const onClickDeleteInvoice = (id: string) => {
  const deletedInvoice: DeleteInvoiceMutationVariables = {
    input: {
      id: id,
    },
  };
  API.graphql(graphqlOperation(deleteInvoice, deletedInvoice));
};

const columns = [
  // { field: 'id', headerName: 'id', width: 90, editable: false },
  {
    field: "datetime",
    headerName: "日時",
    width: 240,
    editable: true,
    renderCell: (params: any) => {
      return <div>{moment(params.value).format("YYYY/MM/DD")}</div>;
      //   return (
      //     <div>
      //       <DatePicker
      //         dateFormat="yyyy/MM/dd"
      //         locale="ja"
      //         selected={params.value}
      //         // minDate={Today}
      //         // onChange={onFormChange}
      //         onChange={(selectedDate) => {
      //           // setDate(selectedDate || Today);
      //         }}
      //       />
      //     </div>
      //   );
    },
  },

  {
    field: "請求書（商品一覧）",
    headerName: "請求書（商品一覧）",
    width: 270,
    disableClickEventBubbling: true,
    renderCell: (params: any) => {
      const onClickItem = () => {
        onClickDeleteInvoice(params.id);

        return;
      };

      return (
        // <Link to={`/invoice/${params.id}/item`} />
        <Button
          variant="contained"
          // style={{color: ''}}
          color="default"
          name="commit"
          // onClick={onClickItem}
        >
          <Link to={`/invoice/${params.id}/item`}>請求書</Link>
        </Button>
      );
    },
  },
  { field: "id", headerName: "請求書番号", width: 180, editable: false },
  { field: "suppliers", headerName: "取引先", width: 180, editable: true },
  { field: "note", headerName: "取引先登録番号", width: 180, editable: true },
  {
    field: "suppliers_address",
    headerName: "取引先住所",
    width: 180,
    editable: true,
  },
  {
    field: "bussiness_information",
    headerName: "事業情報（自社情報）",
    width: 180,
    editable: true,
  },
  { field: "status", headerName: "ステータス", width: 180, editable: true },
  {
    field: "total_ammount",
    headerName: "請求金額(商品一覧から自動計算)",
    width: 300,
    editable: false,
  },
  {
    field: "total_ammount",
    headerName: "請求金額(商品一覧から自動計算)",
    width: 300,
    editable: true,
    renderCell: (params: any) => {
      return <div>{params.value}円</div>;
    },
  },

  {
    field: "issue_date",
    headerName: "発行日",
    width: 240,
    editable: true,
    renderCell: (params: any) => {
      return <div>{moment(params.value).format("YYYY/MM/DD")}</div>;
    },
  },

  {
    field: "due_date",
    headerName: "支払期限",
    width: 240,
    editable: true,
    renderCell: (params: any) => {
      return <div>{moment(params.value).format("YYYY/MM/DD")}</div>;
    },
  },

  { field: "send_offline", headerName: "送付", width: 180, editable: true },
  { field: "send_online", headerName: "送信", width: 180, editable: true },

  // { field: "person", headerName: "担当者", width: 180, editable: true },
  { field: "assignee", headerName: "担当者", width: 180, editable: true },

  {
    field: "account_information",
    headerName: "口座情報",
    width: 180,
    editable: true,
  },
  // { field: "user_id", headerName: "貸方", width: 180, editable: true },
  // {
  //     field: 'createdAt',
  //     headerName: '追加日',
  //     type: 'date',
  //     width: 240,
  //     editable: false,
  // },

  {
    field: "削除",
    headerName: "削除",
    width: 130,
    disableClickEventBubbling: true,
    renderCell: (params: any) => {
      const onClick = () => {
        onClickDeleteInvoice(params.id);
        return;
      };

      return (
        <Button
          variant="contained"
          color="secondary"
          name="commit"
          onClick={onClick}
        >
          削除
        </Button>
      );
    },
  },
];

type FormState = {
  id: string;
  title?: string | null;
  suppliers?: string | null;
  suppliers_address?: string | null;
  bussiness_information?: string | null;
  status?: string | null;
  charge_amount?: number | null;
  issue_date?: string | null;
  due_date?: string | null;
  send_offline?: string | null;
  send_online?: string | null;
  person?: string | null;
  assignee?: string | null;
  account_information?: string | null;
  items?: any;
  note?: string | null;
  total_ammount?: number | null;
  user_id: string;
};

export type Invoice = FormState & {
  createdAt: string;
  updatedAt: string;
};

type InvoiceSubscriptionEventCreate = {
  value: { data: OnCreateInvoiceSubscription };
};
type InvoiceSubscriptionEventDelete = {
  value: { data: OnDeleteInvoiceSubscription };
};
type InvoiceSubscriptionEventUpdate = {
  value: { data: OnUpdateInvoiceSubscription };
};

const useInvoices = () => {
  const [Invoices, setInvoices] = useState<Invoice[]>([]);

  useEffect(() => {
    (async () => {
      let nextToken;
      let _items: Invoice[] = [];
      while (true) {
        const result = await API.graphql(
          graphqlOperation(listInvoicesByUser, {
            user_id: getUserId(),
            nextToken: nextToken,
          })
        );
        if ("data" in result && result.data) {
          const Invoices = result.data as ListInvoicesByUserQuery;
          if (Invoices.listInvoicesByUser) {
            let items = Invoices.listInvoicesByUser.items as Invoice[];
            // console.log(items);
            items = items.map((obj) => {
              console.log(obj);
              const _obj = Object.assign({}, obj);
              if (typeof _obj.items === "undefined") {
                _obj.items = [];
                return _obj;
              }
              if (typeof _obj.items.items === "undefined") {
                _obj.total_ammount = 0.0;
                return _obj;
              }
              const total_ammount = _obj.items.items.map((val: any) => {
                console.log(val);
                let res =
                  parseInt(val.price) *
                  parseInt(val.ammount) *
                  (1 + parseFloat(val.tax_rate) / 100);
                return Math.round(res);
              });
              _obj.total_ammount = total_ammount.reduce(function (
                sum: number,
                element: any
              ) {
                return sum + element;
              },
              0); // parseInt(_obj.items.price) * parseInt(_obj.items.ammount)
              return _obj;
            });
            // items.total_ammount = data.listInvoices.items.items
            // console.log(items)

            if (Invoices.listInvoicesByUser) {
              const tempItems = Invoices.listInvoicesByUser.items as Invoice[];
              _items = _items.concat(tempItems);
            }
            if (Invoices.listInvoicesByUser?.nextToken) {
              nextToken = Invoices.listInvoicesByUser?.nextToken;
            } else {
              setInvoices(items as Invoice[]);
              break;
            }
          }
        }
      }

      const clientCreateInvoice = API.graphql(
        graphqlOperation(onCreateInvoice, {
          owner: getUserId(),
        })
      );
      if ("subscribe" in clientCreateInvoice) {
        clientCreateInvoice.subscribe({
          next: ({ value: { data } }: InvoiceSubscriptionEventCreate) => {
            if (data.onCreateInvoice) {
              const invoice: Invoice = data.onCreateInvoice;
              setInvoices((prev) => [...prev, invoice]);
            }
          },
        });
      }

      const clientDeleteInvoice = API.graphql(
        graphqlOperation(onDeleteInvoice, {
          owner: getUserId(),
        })
      );
      if ("subscribe" in clientDeleteInvoice) {
        clientDeleteInvoice.subscribe({
          next: ({ value: { data } }: InvoiceSubscriptionEventDelete) => {
            if (data.onDeleteInvoice) {
              const invoice: Invoice = data.onDeleteInvoice;
              setInvoices((prev) => {
                return prev.filter((obj) => obj.id !== invoice.id);
              });
            }
          },
        });
      }

      const clientUpdateInvoice = API.graphql(
        graphqlOperation(onUpdateInvoice, {
          owner: getUserId(),
        })
      );
      if ("subscribe" in clientUpdateInvoice) {
        clientUpdateInvoice.subscribe({
          next: ({ value: { data } }: InvoiceSubscriptionEventUpdate) => {
            if (data.onUpdateInvoice) {
              const invoice: Invoice = data.onUpdateInvoice;
              setInvoices((prev) => [
                ...prev.filter((obj) => obj.id !== invoice.id),
                invoice,
              ]);
            }
          },
        });
      }
    })();
  }, []);

  return Invoices;
};

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
});

const ProductPage: React.FC = () => {
  const [input, setInput] = useState<FormState>({
    id: "",
    title: "",
    suppliers: "",
    suppliers_address: "",
    bussiness_information: "",
    status: "",
    charge_amount: 0,
    issue_date: "",
    due_date: "",
    send_offline: "",
    send_online: "",
    person: "",
    assignee: "",
    account_information: "",
    note: "",
    items: undefined,
    total_ammount: 0,
    user_id: getUserId(),
  });
  const invoices = useInvoices();
  // const [user, setUser] = useUser();

  const [filterNosummary, setFilterNosummary] = useState<boolean>();

  const onFormChange = ({
    target: { name, value },
  }: React.ChangeEvent<HTMLInputElement>) => {
    setInput((prev) => ({ ...prev, [name]: value }));
  };

  const onInvoice = () => {
    // if (input.datetime === "") return;
    const newInvoice: CreateInvoiceMutationVariables = {
      input: {
        title: input.title ? String(input.title) : "",
        suppliers: input.suppliers ? String(input.suppliers) : "",
        suppliers_address: input.suppliers_address
          ? String(input.suppliers_address)
          : "",
        bussiness_information: input.bussiness_information
          ? String(input.bussiness_information)
          : "",
        status: input.status ? String(input.status) : "",
        charge_amount: input.charge_amount ? Number(input.charge_amount) : 0,
        issue_date: moment(input.issue_date).format(),
        due_date: moment(input.due_date).format(),
        send_offline: input.send_offline ? String(input.send_offline) : "",
        send_online: input.send_online ? String(input.send_online) : "",
        person: input.person ? String(input.person) : "",
        assignee: input.assignee ? String(input.assignee) : "",
        account_information: input.account_information
          ? String(input.account_information)
          : "",
        note: input.note ? String(input.note) : "",
        user_id: getUserId(),
      },
    };
    const now = moment(new Date()).format();
    setInput({
      id: "",
      title: "",
      suppliers: "",
      suppliers_address: "",
      bussiness_information: "",
      status: "",
      charge_amount: 0,
      issue_date: now,
      due_date: now,
      send_offline: "",
      send_online: "",
      person: "",
      assignee: "",
      account_information: "",
      note: "",
      user_id: getUserId(),
    });
    API.graphql(graphqlOperation(createInvoice, newInvoice));
    // API.graphql({
    //   query: createInvoice,
    //   variables: newInvoice,
    //   authMode: "AMAZON_COGNITO_USER_POOLS",
    // });
  };

  const onEditCellChangeCommitted = (e: any) => {
    console.log(e.field);
    const id = e.id;
    const input: any = { id: id };
    input[e.field] = e.props.value;

    console.log(input);

    const updatedInvoice: UpdateInvoiceMutationVariables = {
      input: input,
    };
    API.graphql(graphqlOperation(updateInvoice, updatedInvoice));
  };

  const Today = new Date();
  const [datetime, setDatetime] = React.useState(Today);
  registerLocale("ja", ja);

  const [modal, setModal] = useState<boolean>(false);
  //   const onClickDatetime = (e) => {
  //     console.log(e);
  //     setModal(true);
  //   };

  // const onChangeDateIssue = (selectedDate: Date) => {
  //   //   console.log(selectedDate);
  //   // selectedDate || Today
  //   setUser((prevState: Invoice) => {
  //     let jasper = Object.assign({}, prevState);
  //     jasper.issue_date = moment(selectedDate).format();
  //     return jasper;
  //   });
  //   const updatedUser: UpdateUserMutationVariables = {
  //     input: {
  //       id: getUserId(),
  //       issue_date: moment(selectedDate).format(),
  //     },
  //   };
  //   API.graphql(graphqlOperation(updateUser, updatedUser));
  // };
  // const onChangeDateDue = (selectedDate: Date) => {
  //   //   console.log(selectedDate);
  //   // selectedDate || Today
  //   setUser((prevState: Invoice) => {
  //     let jasper = Object.assign({}, prevState);
  //     jasper.due_date = moment(selectedDate).format();
  //     return jasper;
  //   });
  //   const updatedUser: UpdateUserMutationVariables = {
  //     input: {
  //       id: getUserId(),
  //       due_date: moment(selectedDate).format(),
  //     },
  //   };
  //   API.graphql(graphqlOperation(updateUser, updatedUser));
  // };

  return (
    <GenericTemplate title="注文一覧">
      <ThemeProvider theme={theme}>
        {/* <Box>
          <div style={{ marginBottom: "10px" }}>
            <DatePicker
              //   className="customDatePicker"
              dateFormat="yyyy/MM/dd"
              locale="ja"
              selected={
                user.datetime_invoice
                  ? moment(user.datetime_invoice).toDate()
                  : undefined
              }
              //   selected={new Date()}
              // minDate={Today}
              // onChange={onFormChange}
              onChange={onChangeDate}
            />
          </div>
        </Box> */}

        <Box p={2} bgcolor="rgb(92, 44, 143);" color="primary.contrastText">
          新規登録 　{" "}
        </Box>

        <Box p={2} bgcolor="secondary.main" color="rgb(92, 44, 143);">
          <form action="/users" acceptCharset="UTF-8" method="post">
            <div>
              <TextField
                id="title"
                type="text"
                name="title"
                label="請求書タイトル"
                style={{ width: 500 }}
                value={input.title}
                onChange={onFormChange}
              />
            </div>

            <div>
              <TextField
                id="suppliers"
                type="text"
                name="suppliers"
                label="取引先"
                style={{ width: 500 }}
                value={input.suppliers}
                onChange={onFormChange}
              />
            </div>

            <div>
              <TextField
                id="note"
                type="text"
                name="note"
                label="取引先登録番号"
                style={{ width: 500 }}
                value={input.note}
                onChange={onFormChange}
              />
            </div>

            <div>
              <TextField
                id="suppliers_address"
                type="text"
                name="suppliers_address"
                label="取引先住所"
                style={{ width: 500 }}
                value={input.suppliers_address}
                onChange={onFormChange}
              />
            </div>

            <div>
              <TextField
                id="bussiness_information"
                type="text"
                name="bussiness_information"
                label="事業情報（自社情報）"
                style={{ width: 500 }}
                value={input.bussiness_information}
                onChange={onFormChange}
              />
            </div>

            <div>
              <TextField
                id="status"
                type="text"
                name="status"
                label="ステータス"
                style={{ width: 500 }}
                value={input.status}
                onChange={onFormChange}
              />
            </div>

            {/* <div>
              <TextField
                id="charge_amount"
                type="text"
                name="charge_amount"
                label="請求金額"
                style={{ width: 500 }}
                value={input.charge_amount}
                onChange={onFormChange}
              />
            </div> */}

            <div>
              <label>発行日</label>
              <DatePicker
                className="customDatePicker"
                dateFormat="yyyy/MM/dd"
                locale="ja"
                selected={
                  input.issue_date
                    ? moment(input.issue_date).toDate()
                    : undefined
                }
                // minDate={Today}
                // onChange={onFormChange}
                onChange={(selectedDate) => {
                  //   console.log(selectedDate);
                  setInput((prev) => ({
                    ...prev,
                    ["issue_date"]: moment(selectedDate).format(),
                  }));
                  // setDatetime(selectedDate || Today);
                }}
              />
            </div>

            <div>
              <label>支払期限</label>
              <DatePicker
                className="customDatePicker"
                dateFormat="yyyy/MM/dd"
                locale="ja"
                selected={
                  input.due_date ? moment(input.due_date).toDate() : undefined
                }
                // minDate={Today}
                // onChange={onFormChange}
                onChange={(selectedDate) => {
                  //   console.log(selectedDate);
                  setInput((prev) => ({
                    ...prev,
                    ["due_date"]: moment(selectedDate).format(),
                  }));
                  // setDatetime(selectedDate || Today);
                }}
              />
            </div>

            <div>
              <TextField
                id="send_offline"
                type="text"
                name="send_offline"
                label="送付"
                style={{ width: 500 }}
                value={input.send_offline}
                onChange={onFormChange}
              />
            </div>

            <div>
              <TextField
                id="send_online"
                type="text"
                name="send_online"
                label="送信"
                style={{ width: 500 }}
                value={input.send_online}
                onChange={onFormChange}
              />
            </div>

            {/* <div>
              <TextField
                id="person"
                type="text"
                name="person"
                label="担当者"
                style={{ width: 500 }}
                value={input.person}
                onChange={onFormChange}
              />
            </div> */}

            <div>
              <TextField
                id="assignee"
                type="text"
                name="assignee"
                label="担当者"
                style={{ width: 500 }}
                value={input.assignee}
                onChange={onFormChange}
              />
            </div>

            <div>
              <TextField
                id="account_information"
                type="text"
                name="account_information"
                label="口座情報"
                style={{ width: 500 }}
                value={input.account_information}
                onChange={onFormChange}
              />
            </div>

            <Button
              style={{ margin: "10px auto 10px auto" }}
              variant="contained"
              color="inherit"
              name="commit"
              onClick={onInvoice}
            >
              出力
            </Button>
          </form>
        </Box>
      </ThemeProvider>

      <div style={{ height: 700, width: "100%" }}>
        {/* <Modal
          open={modal}
          onClose={() => {
            setModal(false);
          }}
          //   aria-labelledby="modal-modal-title"
          //   aria-describedby="modal-modal-description"
        >
          <div>
            <DatePicker
              dateFormat="yyyy/MM/dd"
              locale="ja"
              selected={datetime}
              // minDate={Today}
              // onChange={onFormChange}
              onChange={(selectedDate) => {
                setDatetime(selectedDate || Today);
              }}
            />
          </div>
        </Modal> */}

        <DataGrid
          rows={invoices}
          columns={columns}
          onCellClick={(e) => {
            console.log(e);
            setModal(true);
          }}
          onEditCellChangeCommitted={onEditCellChangeCommitted}
          // checkboxSelection
          disableColumnMenu={false}
          sortModel={[
            {
              field: "issue_date",
              sort: "asc",
            },
          ]}
        />
      </div>
      <div></div>
    </GenericTemplate>
  );
};

export default ProductPage;
