import GenericTemplate from "../templates/GenericTemplate";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import { ThemeProvider } from "@material-ui/styles";
import Box from "@material-ui/core/Box";
import { createMuiTheme } from "@material-ui/core/styles";

import moment from "moment";
import DatePicker, { registerLocale } from "react-datepicker";
import ja from "date-fns/locale/ja";

import React, { useEffect, useState } from "react";
import { API, graphqlOperation } from "aws-amplify";
import { listJournals, getUser } from "../../graphql/queries";
import {
  createUser,
  createJournal,
  updateJournal,
  deleteJournal,
  updateUser,
} from "../../graphql/mutations";
import {
  onCreateJournal,
  onDeleteJournal,
  onUpdateJournal,
} from "../../graphql/subscriptions";
import {
  GetUserQuery,
  GetUserQueryVariables,
  CreateUserMutationVariables,
  UpdateUserMutationVariables,
  ListJournalsQuery,
  OnCreateJournalSubscription,
  OnDeleteJournalSubscription,
  CreateJournalMutationVariables,
  UpdateJournalMutationVariables,
  DeleteJournalMutationVariables,
  OnUpdateJournalSubscription,
} from "../../API";

import { DataGrid } from "@material-ui/data-grid";

import { getUserId } from "../../util";

import { useUser, User } from "../../util/user";

const theme = createMuiTheme({
  palette: {
    primary: { main: "#00838f" },
    secondary: { main: "#e0f7fa" },
  },
});

const onClickDeleteJournal = (id: string) => {
  const deletedJournal: DeleteJournalMutationVariables = {
    input: {
      id: id,
    },
  };
  API.graphql(graphqlOperation(deleteJournal, deletedJournal));
};

const columns = [
  // { field: 'id', headerName: 'id', width: 90, editable: false },
  {
    field: "datetime",
    headerName: "日時",
    width: 240,
    editable: true,
    renderCell: (params: any) => {
      return <div>{moment(params.value).format("YYYY/MM/DD")}</div>;
      //   return (
      //     <div>
      //       <DatePicker
      //         dateFormat="yyyy/MM/dd"
      //         locale="ja"
      //         selected={params.value}
      //         // minDate={Today}
      //         // onChange={onFormChange}
      //         onChange={(selectedDate) => {
      //           // setDate(selectedDate || Today);
      //         }}
      //       />
      //     </div>
      //   );
    },
  },
  { field: "summary", headerName: "摘要", width: 180, editable: true },
  { field: "ledger", headerName: "元丁", width: 180, editable: true },
  { field: "debit", headerName: "借方", width: 180, editable: true },
  { field: "credit", headerName: "貸方", width: 180, editable: true },
  // {
  //     field: 'createdAt',
  //     headerName: '追加日',
  //     type: 'date',
  //     width: 240,
  //     editable: false,
  // },
  {
    field: "",
    headerName: "",
    width: 85,
    disableClickEventBubbling: true,
    renderCell: (params: any) => {
      const onClick = () => {
        onClickDeleteJournal(params.id);
        return;
      };

      return (
        <Button
          variant="contained"
          color="secondary"
          name="commit"
          onClick={onClick}
        >
          削除
        </Button>
      );
    },
  },
];

type FormState = {
  id: string;
  datetime: string;
  summary?: string | null;
  ledger?: string | null;
  debit?: number | null;
  credit?: number | null;
  user_id: string;
};

type Journal = FormState & {
  createdAt: string;
  updatedAt: string;
};

type JournalSubscriptionEventCreate = {
  value: { data: OnCreateJournalSubscription };
};
type JournalSubscriptionEventDelete = {
  value: { data: OnDeleteJournalSubscription };
};
type JournalSubscriptionEventUpdate = {
  value: { data: OnUpdateJournalSubscription };
};

const useJournals = () => {
  const [Journals, setJournals] = useState<Journal[]>([]);

  useEffect(() => {
    (async () => {
      let nextToken;
      let items: Journal[] = [];
      while (true) {
        const result = await API.graphql(
          graphqlOperation(listJournals, {
            owner: getUserId(),
            nextToken: nextToken,
          })
        );
        if ("data" in result && result.data) {
          const newJournals = result.data as ListJournalsQuery;

          if (newJournals.listJournals) {
            const tempItems = newJournals.listJournals.items as Journal[];
            items = items.concat(tempItems);
          }
          if (newJournals.listJournals?.nextToken) {
            nextToken = newJournals.listJournals?.nextToken;
          } else {
            setJournals(items as Journal[]);
            break;
          }
        }
      }

      const clientCreateJournal = API.graphql(
        graphqlOperation(onCreateJournal, {
          owner: getUserId(),
        })
      );
      if ("subscribe" in clientCreateJournal) {
        clientCreateJournal.subscribe({
          next: ({ value: { data } }: JournalSubscriptionEventCreate) => {
            if (data.onCreateJournal) {
              const journal: Journal = data.onCreateJournal;
              setJournals((prev) => [...prev, journal]);
            }
          },
        });
      }

      const clientDeleteJournal = API.graphql(
        graphqlOperation(onDeleteJournal, {
          owner: getUserId(),
        })
      );
      if ("subscribe" in clientDeleteJournal) {
        clientDeleteJournal.subscribe({
          next: ({ value: { data } }: JournalSubscriptionEventDelete) => {
            if (data.onDeleteJournal) {
              const journal: Journal = data.onDeleteJournal;
              setJournals((prev) => {
                return prev.filter((obj) => obj.id !== journal.id);
              });
            }
          },
        });
      }

      const clientUpdateJournal = API.graphql(
        graphqlOperation(onUpdateJournal, {
          owner: getUserId(),
        })
      );
      if ("subscribe" in clientUpdateJournal) {
        clientUpdateJournal.subscribe({
          next: ({ value: { data } }: JournalSubscriptionEventUpdate) => {
            if (data.onUpdateJournal) {
              const journal: Journal = data.onUpdateJournal;
              setJournals((prev) => [
                ...prev.filter((obj) => obj.id !== journal.id),
                journal,
              ]);
            }
          },
        });
      }
    })();
  }, []);

  return Journals;
};

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
});

const ProductPage: React.FC = () => {
  const [input, setInput] = useState<FormState>({
    id: "",
    datetime: "",
    summary: "",
    ledger: "",
    debit: 0,
    credit: 0,
    user_id: getUserId(),
  });
  const journals = useJournals();
  const [user, setUser] = useUser();

  const [filterNosummary, setFilterNosummary] = useState<boolean>();

  const onFormChange = ({
    target: { name, value },
  }: React.ChangeEvent<HTMLInputElement>) => {
    // console.log(name, value)
    setUser((prevState: User) => {
      let obj = Object.assign({}, prevState);
      obj.name = value;
      return obj;
    });
  };

  const onClick = async (e: any) => {
    // e.preventDefault();
    // console.log(user.name)
    const updatedUser: UpdateUserMutationVariables = {
      input: {
        id: getUserId(),
        name: user.name,
      },
    };
    API.graphql(graphqlOperation(updateUser, updatedUser));
  };

  const onJournal = () => {
    // if (input.datetime === "") return;
    const newJournal: CreateJournalMutationVariables = {
      input: {
        datetime: moment(datetime).format(),
        summary: input.summary ? String(input.summary) : "",
        ledger: input.ledger ? String(input.ledger) : "",
        debit: input.debit ? Number(input.debit) : 0,
        credit: input.credit ? Number(input.credit) : 0,
        user_id: getUserId(),
      },
    };
    setInput({
      id: "",
      datetime: "",
      summary: "",
      ledger: "",
      debit: 0,
      credit: 0,
      user_id: getUserId(),
    });
    API.graphql(graphqlOperation(createJournal, newJournal));
    // API.graphql({
    //   query: createJournal,
    //   variables: newJournal,
    //   authMode: "AMAZON_COGNITO_USER_POOLS",
    // });
  };

  const onEditCellChangeCommitted = (e: any) => {
    // console.log(e.field);
    const id = e.id;
    let input;
    if (e.field == "datetime") {
      input = {
        id: id,
        datetime: e.props.value,
      };
    } else if (e.field == "summary") {
      input = {
        id: id,
        summary: e.props.value ? String(e.props.value) : "",
      };
    } else if (e.field == "ledger") {
      input = {
        id: id,
        ledger: e.props.value ? String(e.props.value) : "",
      };
    } else if (e.field == "debit") {
      input = {
        id: id,
        debit: e.props.value ? Number(e.props.value) : 0,
      };
    } else if (e.field == "credit") {
      input = {
        id: id,
        credit: e.props.value ? Number(e.props.value) : 0,
      };
    } else {
      return;
    }
    // console.log(input);

    const updatedJournal: UpdateJournalMutationVariables = {
      input: input,
    };
    API.graphql(graphqlOperation(updateJournal, updatedJournal));
  };

  const Today = new Date();
  const [datetime, setDatetime] = React.useState(Today);
  registerLocale("ja", ja);

  // const [user, setUser] = useUser();

  const [modal, setModal] = useState<boolean>(false);
  //   const onClickDatetime = (e) => {
  //     console.log(e);
  //     setModal(true);
  //   };

  const onChangeDate = (selectedDate: Date) => {
    //   console.log(selectedDate);
    // selectedDate || Today
    setUser((prevState: User) => {
      let jasper = Object.assign({}, prevState);
      jasper.datetime_journal = moment(selectedDate).format();
      return jasper;
    });
    const updatedUser: UpdateUserMutationVariables = {
      input: {
        id: getUserId(),
        datetime_journal: moment(selectedDate).format(),
      },
    };
    API.graphql(graphqlOperation(updateUser, updatedUser));
  };

  return (
    <GenericTemplate title="ユーザー設定">
      <Box>
        <div style={{ marginBottom: "10px" }}>
          <div>
            <label>表示名</label>
          </div>
          <div style={{ margin: "", display: "flex" }}>
            <TextField
              id="name"
              type="text"
              name="name"
              // label="表示名"
              style={{ width: 500 }}
              value={user.name}
              onChange={onFormChange}
            />
            <Button
              style={{ margin: "auto 10px auto 10px" }}
              variant="contained"
              color="inherit"
              name="commit"
              onClick={onClick}
            >
              登録
            </Button>
          </div>
        </div>
      </Box>
    </GenericTemplate>
  );
};

export default ProductPage;
