/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

import * as APITypes from "../API";
type GeneratedQuery<InputType, OutputType> = string & {
  __generatedQueryInput: InputType;
  __generatedQueryOutput: OutputType;
};

export const getUser = /* GraphQL */ `query GetUser($id: ID!) {
  getUser(id: $id) {
    id
    name
    datetime_journal
    datetime_cash_register
    datetime_general_ledger
    datetime_trial_balance
    datetime_balance_sheet
    datetime_since_profit_and_loss_statement
    datetime_to_profit_and_loss_statement
    datetime_since_cash_flow_statement
    datetime_to_cash_flow_statement
    start_balance_cash_flow_statement
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<APITypes.GetUserQueryVariables, APITypes.GetUserQuery>;
export const listUsers = /* GraphQL */ `query ListUsers(
  $filter: ModelUserFilterInput
  $limit: Int
  $nextToken: String
) {
  listUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      name
      datetime_journal
      datetime_cash_register
      datetime_general_ledger
      datetime_trial_balance
      datetime_balance_sheet
      datetime_since_profit_and_loss_statement
      datetime_to_profit_and_loss_statement
      datetime_since_cash_flow_statement
      datetime_to_cash_flow_statement
      start_balance_cash_flow_statement
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<APITypes.ListUsersQueryVariables, APITypes.ListUsersQuery>;
export const getJournal = /* GraphQL */ `query GetJournal($id: ID!) {
  getJournal(id: $id) {
    id
    datetime
    summary
    ledger
    debit
    credit
    user_id
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetJournalQueryVariables,
  APITypes.GetJournalQuery
>;
export const listJournals = /* GraphQL */ `query ListJournals(
  $filter: ModelJournalFilterInput
  $limit: Int
  $nextToken: String
) {
  listJournals(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      datetime
      summary
      ledger
      debit
      credit
      user_id
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListJournalsQueryVariables,
  APITypes.ListJournalsQuery
>;
export const getCashRegister = /* GraphQL */ `query GetCashRegister($id: ID!) {
  getCashRegister(id: $id) {
    id
    datetime
    account
    summary
    deposit_amount
    withdrawal_amount
    balance
    user_id
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetCashRegisterQueryVariables,
  APITypes.GetCashRegisterQuery
>;
export const listCashRegisters = /* GraphQL */ `query ListCashRegisters(
  $filter: ModelCashRegisterFilterInput
  $limit: Int
  $nextToken: String
) {
  listCashRegisters(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      datetime
      account
      summary
      deposit_amount
      withdrawal_amount
      balance
      user_id
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListCashRegistersQueryVariables,
  APITypes.ListCashRegistersQuery
>;
export const getGeneralLedger = /* GraphQL */ `query GetGeneralLedger($id: ID!) {
  getGeneralLedger(id: $id) {
    id
    datetime
    summary
    account
    journal
    debit
    credit
    balance
    user_id
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetGeneralLedgerQueryVariables,
  APITypes.GetGeneralLedgerQuery
>;
export const listGeneralLedgers = /* GraphQL */ `query ListGeneralLedgers(
  $filter: ModelGeneralLedgerFilterInput
  $limit: Int
  $nextToken: String
) {
  listGeneralLedgers(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      datetime
      summary
      account
      journal
      debit
      credit
      balance
      user_id
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListGeneralLedgersQueryVariables,
  APITypes.ListGeneralLedgersQuery
>;
export const getTrialBalance = /* GraphQL */ `query GetTrialBalance($id: ID!) {
  getTrialBalance(id: $id) {
    id
    debit
    account
    credit
    user_id
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetTrialBalanceQueryVariables,
  APITypes.GetTrialBalanceQuery
>;
export const listTrialBalances = /* GraphQL */ `query ListTrialBalances(
  $filter: ModelTrialBalanceFilterInput
  $limit: Int
  $nextToken: String
) {
  listTrialBalances(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      debit
      account
      credit
      user_id
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListTrialBalancesQueryVariables,
  APITypes.ListTrialBalancesQuery
>;
export const getBalanceSheet = /* GraphQL */ `query GetBalanceSheet($id: ID!) {
  getBalanceSheet(id: $id) {
    id
    type
    key
    value
    user_id
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetBalanceSheetQueryVariables,
  APITypes.GetBalanceSheetQuery
>;
export const listBalanceSheets = /* GraphQL */ `query ListBalanceSheets(
  $filter: ModelBalanceSheetFilterInput
  $limit: Int
  $nextToken: String
) {
  listBalanceSheets(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      type
      key
      value
      user_id
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListBalanceSheetsQueryVariables,
  APITypes.ListBalanceSheetsQuery
>;
export const getProfitAndLossStatement = /* GraphQL */ `query GetProfitAndLossStatement($id: ID!) {
  getProfitAndLossStatement(id: $id) {
    id
    account
    debit
    credit
    user_id
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetProfitAndLossStatementQueryVariables,
  APITypes.GetProfitAndLossStatementQuery
>;
export const listProfitAndLossStatements = /* GraphQL */ `query ListProfitAndLossStatements(
  $filter: ModelProfitAndLossStatementFilterInput
  $limit: Int
  $nextToken: String
) {
  listProfitAndLossStatements(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      account
      debit
      credit
      user_id
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListProfitAndLossStatementsQueryVariables,
  APITypes.ListProfitAndLossStatementsQuery
>;
export const getCashFlowStatement = /* GraphQL */ `query GetCashFlowStatement($id: ID!) {
  getCashFlowStatement(id: $id) {
    id
    type
    key
    value
    user_id
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetCashFlowStatementQueryVariables,
  APITypes.GetCashFlowStatementQuery
>;
export const listCashFlowStatements = /* GraphQL */ `query ListCashFlowStatements(
  $filter: ModelCashFlowStatementFilterInput
  $limit: Int
  $nextToken: String
) {
  listCashFlowStatements(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      type
      key
      value
      user_id
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListCashFlowStatementsQueryVariables,
  APITypes.ListCashFlowStatementsQuery
>;
export const getInvoice = /* GraphQL */ `query GetInvoice($id: ID!) {
  getInvoice(id: $id) {
    id
    title
    suppliers
    suppliers_address
    bussiness_information
    status
    charge_amount
    issue_date
    due_date
    send_offline
    send_online
    person
    assignee
    note
    account_information
    user_id
    items {
      nextToken
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetInvoiceQueryVariables,
  APITypes.GetInvoiceQuery
>;
export const listInvoices = /* GraphQL */ `query ListInvoices(
  $filter: ModelInvoiceFilterInput
  $limit: Int
  $nextToken: String
) {
  listInvoices(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      title
      suppliers
      suppliers_address
      bussiness_information
      status
      charge_amount
      issue_date
      due_date
      send_offline
      send_online
      person
      assignee
      note
      account_information
      user_id
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListInvoicesQueryVariables,
  APITypes.ListInvoicesQuery
>;
export const getItem = /* GraphQL */ `query GetItem($id: ID!) {
  getItem(id: $id) {
    id
    name
    item_code
    price
    ammount
    unit
    description
    tax_rate
    start_date
    period
    user_id
    invoice {
      id
      title
      suppliers
      suppliers_address
      bussiness_information
      status
      charge_amount
      issue_date
      due_date
      send_offline
      send_online
      person
      assignee
      note
      account_information
      user_id
      createdAt
      updatedAt
      __typename
    }
    createdAt
    updatedAt
    invoiceItemsId
    __typename
  }
}
` as GeneratedQuery<APITypes.GetItemQueryVariables, APITypes.GetItemQuery>;
export const listItems = /* GraphQL */ `query ListItems(
  $filter: ModelItemFilterInput
  $limit: Int
  $nextToken: String
) {
  listItems(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      name
      item_code
      price
      ammount
      unit
      description
      tax_rate
      start_date
      period
      user_id
      createdAt
      updatedAt
      invoiceItemsId
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<APITypes.ListItemsQueryVariables, APITypes.ListItemsQuery>;
export const listJournalsByUser = /* GraphQL */ `query ListJournalsByUser(
  $user_id: ID!
  $datetime: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelJournalFilterInput
  $limit: Int
  $nextToken: String
) {
  listJournalsByUser(
    user_id: $user_id
    datetime: $datetime
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      datetime
      summary
      ledger
      debit
      credit
      user_id
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListJournalsByUserQueryVariables,
  APITypes.ListJournalsByUserQuery
>;
export const listCashRegistersByUser = /* GraphQL */ `query ListCashRegistersByUser(
  $user_id: ID!
  $datetime: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelCashRegisterFilterInput
  $limit: Int
  $nextToken: String
) {
  listCashRegistersByUser(
    user_id: $user_id
    datetime: $datetime
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      datetime
      account
      summary
      deposit_amount
      withdrawal_amount
      balance
      user_id
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListCashRegistersByUserQueryVariables,
  APITypes.ListCashRegistersByUserQuery
>;
export const listGeneralLedgersByUser = /* GraphQL */ `query ListGeneralLedgersByUser(
  $user_id: ID!
  $datetime: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelGeneralLedgerFilterInput
  $limit: Int
  $nextToken: String
) {
  listGeneralLedgersByUser(
    user_id: $user_id
    datetime: $datetime
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      datetime
      summary
      account
      journal
      debit
      credit
      balance
      user_id
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListGeneralLedgersByUserQueryVariables,
  APITypes.ListGeneralLedgersByUserQuery
>;
export const listTrialsBalancesByUser = /* GraphQL */ `query ListTrialsBalancesByUser(
  $user_id: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelTrialBalanceFilterInput
  $limit: Int
  $nextToken: String
) {
  listTrialsBalancesByUser(
    user_id: $user_id
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      debit
      account
      credit
      user_id
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListTrialsBalancesByUserQueryVariables,
  APITypes.ListTrialsBalancesByUserQuery
>;
export const listBalanceSheetsByUser = /* GraphQL */ `query ListBalanceSheetsByUser(
  $user_id: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelBalanceSheetFilterInput
  $limit: Int
  $nextToken: String
) {
  listBalanceSheetsByUser(
    user_id: $user_id
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      type
      key
      value
      user_id
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListBalanceSheetsByUserQueryVariables,
  APITypes.ListBalanceSheetsByUserQuery
>;
export const listProfitAndLossStatementsByUser = /* GraphQL */ `query ListProfitAndLossStatementsByUser(
  $user_id: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelProfitAndLossStatementFilterInput
  $limit: Int
  $nextToken: String
) {
  listProfitAndLossStatementsByUser(
    user_id: $user_id
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      account
      debit
      credit
      user_id
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListProfitAndLossStatementsByUserQueryVariables,
  APITypes.ListProfitAndLossStatementsByUserQuery
>;
export const listCashFlowStatementsByUser = /* GraphQL */ `query ListCashFlowStatementsByUser(
  $user_id: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelCashFlowStatementFilterInput
  $limit: Int
  $nextToken: String
) {
  listCashFlowStatementsByUser(
    user_id: $user_id
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      type
      key
      value
      user_id
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListCashFlowStatementsByUserQueryVariables,
  APITypes.ListCashFlowStatementsByUserQuery
>;
export const listInvoicesByUser = /* GraphQL */ `query ListInvoicesByUser(
  $user_id: ID!
  $issue_date: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelInvoiceFilterInput
  $limit: Int
  $nextToken: String
) {
  listInvoicesByUser(
    user_id: $user_id
    issue_date: $issue_date
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      title
      suppliers
      suppliers_address
      bussiness_information
      status
      charge_amount
      issue_date
      due_date
      send_offline
      send_online
      person
      assignee
      note
      account_information
      user_id
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListInvoicesByUserQueryVariables,
  APITypes.ListInvoicesByUserQuery
>;
export const listItemsByUser = /* GraphQL */ `query ListItemsByUser(
  $user_id: ID!
  $start_date: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelItemFilterInput
  $limit: Int
  $nextToken: String
) {
  listItemsByUser(
    user_id: $user_id
    start_date: $start_date
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      name
      item_code
      price
      ammount
      unit
      description
      tax_rate
      start_date
      period
      user_id
      createdAt
      updatedAt
      invoiceItemsId
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListItemsByUserQueryVariables,
  APITypes.ListItemsByUserQuery
>;
