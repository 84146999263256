/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

import * as APITypes from "../API";
type GeneratedSubscription<InputType, OutputType> = string & {
  __generatedSubscriptionInput: InputType;
  __generatedSubscriptionOutput: OutputType;
};

export const onCreateUser = /* GraphQL */ `subscription OnCreateUser(
  $filter: ModelSubscriptionUserFilterInput
  $id: String
) {
  onCreateUser(filter: $filter, id: $id) {
    id
    name
    datetime_journal
    datetime_cash_register
    datetime_general_ledger
    datetime_trial_balance
    datetime_balance_sheet
    datetime_since_profit_and_loss_statement
    datetime_to_profit_and_loss_statement
    datetime_since_cash_flow_statement
    datetime_to_cash_flow_statement
    start_balance_cash_flow_statement
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateUserSubscriptionVariables,
  APITypes.OnCreateUserSubscription
>;
export const onUpdateUser = /* GraphQL */ `subscription OnUpdateUser(
  $filter: ModelSubscriptionUserFilterInput
  $id: String
) {
  onUpdateUser(filter: $filter, id: $id) {
    id
    name
    datetime_journal
    datetime_cash_register
    datetime_general_ledger
    datetime_trial_balance
    datetime_balance_sheet
    datetime_since_profit_and_loss_statement
    datetime_to_profit_and_loss_statement
    datetime_since_cash_flow_statement
    datetime_to_cash_flow_statement
    start_balance_cash_flow_statement
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateUserSubscriptionVariables,
  APITypes.OnUpdateUserSubscription
>;
export const onDeleteUser = /* GraphQL */ `subscription OnDeleteUser(
  $filter: ModelSubscriptionUserFilterInput
  $id: String
) {
  onDeleteUser(filter: $filter, id: $id) {
    id
    name
    datetime_journal
    datetime_cash_register
    datetime_general_ledger
    datetime_trial_balance
    datetime_balance_sheet
    datetime_since_profit_and_loss_statement
    datetime_to_profit_and_loss_statement
    datetime_since_cash_flow_statement
    datetime_to_cash_flow_statement
    start_balance_cash_flow_statement
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteUserSubscriptionVariables,
  APITypes.OnDeleteUserSubscription
>;
export const onCreateJournal = /* GraphQL */ `subscription OnCreateJournal(
  $filter: ModelSubscriptionJournalFilterInput
  $user_id: String
) {
  onCreateJournal(filter: $filter, user_id: $user_id) {
    id
    datetime
    summary
    ledger
    debit
    credit
    user_id
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateJournalSubscriptionVariables,
  APITypes.OnCreateJournalSubscription
>;
export const onUpdateJournal = /* GraphQL */ `subscription OnUpdateJournal(
  $filter: ModelSubscriptionJournalFilterInput
  $user_id: String
) {
  onUpdateJournal(filter: $filter, user_id: $user_id) {
    id
    datetime
    summary
    ledger
    debit
    credit
    user_id
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateJournalSubscriptionVariables,
  APITypes.OnUpdateJournalSubscription
>;
export const onDeleteJournal = /* GraphQL */ `subscription OnDeleteJournal(
  $filter: ModelSubscriptionJournalFilterInput
  $user_id: String
) {
  onDeleteJournal(filter: $filter, user_id: $user_id) {
    id
    datetime
    summary
    ledger
    debit
    credit
    user_id
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteJournalSubscriptionVariables,
  APITypes.OnDeleteJournalSubscription
>;
export const onCreateCashRegister = /* GraphQL */ `subscription OnCreateCashRegister(
  $filter: ModelSubscriptionCashRegisterFilterInput
  $user_id: String
) {
  onCreateCashRegister(filter: $filter, user_id: $user_id) {
    id
    datetime
    account
    summary
    deposit_amount
    withdrawal_amount
    balance
    user_id
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateCashRegisterSubscriptionVariables,
  APITypes.OnCreateCashRegisterSubscription
>;
export const onUpdateCashRegister = /* GraphQL */ `subscription OnUpdateCashRegister(
  $filter: ModelSubscriptionCashRegisterFilterInput
  $user_id: String
) {
  onUpdateCashRegister(filter: $filter, user_id: $user_id) {
    id
    datetime
    account
    summary
    deposit_amount
    withdrawal_amount
    balance
    user_id
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateCashRegisterSubscriptionVariables,
  APITypes.OnUpdateCashRegisterSubscription
>;
export const onDeleteCashRegister = /* GraphQL */ `subscription OnDeleteCashRegister(
  $filter: ModelSubscriptionCashRegisterFilterInput
  $user_id: String
) {
  onDeleteCashRegister(filter: $filter, user_id: $user_id) {
    id
    datetime
    account
    summary
    deposit_amount
    withdrawal_amount
    balance
    user_id
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteCashRegisterSubscriptionVariables,
  APITypes.OnDeleteCashRegisterSubscription
>;
export const onCreateGeneralLedger = /* GraphQL */ `subscription OnCreateGeneralLedger(
  $filter: ModelSubscriptionGeneralLedgerFilterInput
  $user_id: String
) {
  onCreateGeneralLedger(filter: $filter, user_id: $user_id) {
    id
    datetime
    summary
    account
    journal
    debit
    credit
    balance
    user_id
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateGeneralLedgerSubscriptionVariables,
  APITypes.OnCreateGeneralLedgerSubscription
>;
export const onUpdateGeneralLedger = /* GraphQL */ `subscription OnUpdateGeneralLedger(
  $filter: ModelSubscriptionGeneralLedgerFilterInput
  $user_id: String
) {
  onUpdateGeneralLedger(filter: $filter, user_id: $user_id) {
    id
    datetime
    summary
    account
    journal
    debit
    credit
    balance
    user_id
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateGeneralLedgerSubscriptionVariables,
  APITypes.OnUpdateGeneralLedgerSubscription
>;
export const onDeleteGeneralLedger = /* GraphQL */ `subscription OnDeleteGeneralLedger(
  $filter: ModelSubscriptionGeneralLedgerFilterInput
  $user_id: String
) {
  onDeleteGeneralLedger(filter: $filter, user_id: $user_id) {
    id
    datetime
    summary
    account
    journal
    debit
    credit
    balance
    user_id
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteGeneralLedgerSubscriptionVariables,
  APITypes.OnDeleteGeneralLedgerSubscription
>;
export const onCreateTrialBalance = /* GraphQL */ `subscription OnCreateTrialBalance(
  $filter: ModelSubscriptionTrialBalanceFilterInput
  $user_id: String
) {
  onCreateTrialBalance(filter: $filter, user_id: $user_id) {
    id
    debit
    account
    credit
    user_id
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateTrialBalanceSubscriptionVariables,
  APITypes.OnCreateTrialBalanceSubscription
>;
export const onUpdateTrialBalance = /* GraphQL */ `subscription OnUpdateTrialBalance(
  $filter: ModelSubscriptionTrialBalanceFilterInput
  $user_id: String
) {
  onUpdateTrialBalance(filter: $filter, user_id: $user_id) {
    id
    debit
    account
    credit
    user_id
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateTrialBalanceSubscriptionVariables,
  APITypes.OnUpdateTrialBalanceSubscription
>;
export const onDeleteTrialBalance = /* GraphQL */ `subscription OnDeleteTrialBalance(
  $filter: ModelSubscriptionTrialBalanceFilterInput
  $user_id: String
) {
  onDeleteTrialBalance(filter: $filter, user_id: $user_id) {
    id
    debit
    account
    credit
    user_id
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteTrialBalanceSubscriptionVariables,
  APITypes.OnDeleteTrialBalanceSubscription
>;
export const onCreateBalanceSheet = /* GraphQL */ `subscription OnCreateBalanceSheet(
  $filter: ModelSubscriptionBalanceSheetFilterInput
  $user_id: String
) {
  onCreateBalanceSheet(filter: $filter, user_id: $user_id) {
    id
    type
    key
    value
    user_id
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateBalanceSheetSubscriptionVariables,
  APITypes.OnCreateBalanceSheetSubscription
>;
export const onUpdateBalanceSheet = /* GraphQL */ `subscription OnUpdateBalanceSheet(
  $filter: ModelSubscriptionBalanceSheetFilterInput
  $user_id: String
) {
  onUpdateBalanceSheet(filter: $filter, user_id: $user_id) {
    id
    type
    key
    value
    user_id
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateBalanceSheetSubscriptionVariables,
  APITypes.OnUpdateBalanceSheetSubscription
>;
export const onDeleteBalanceSheet = /* GraphQL */ `subscription OnDeleteBalanceSheet(
  $filter: ModelSubscriptionBalanceSheetFilterInput
  $user_id: String
) {
  onDeleteBalanceSheet(filter: $filter, user_id: $user_id) {
    id
    type
    key
    value
    user_id
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteBalanceSheetSubscriptionVariables,
  APITypes.OnDeleteBalanceSheetSubscription
>;
export const onCreateProfitAndLossStatement = /* GraphQL */ `subscription OnCreateProfitAndLossStatement(
  $filter: ModelSubscriptionProfitAndLossStatementFilterInput
  $user_id: String
) {
  onCreateProfitAndLossStatement(filter: $filter, user_id: $user_id) {
    id
    account
    debit
    credit
    user_id
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateProfitAndLossStatementSubscriptionVariables,
  APITypes.OnCreateProfitAndLossStatementSubscription
>;
export const onUpdateProfitAndLossStatement = /* GraphQL */ `subscription OnUpdateProfitAndLossStatement(
  $filter: ModelSubscriptionProfitAndLossStatementFilterInput
  $user_id: String
) {
  onUpdateProfitAndLossStatement(filter: $filter, user_id: $user_id) {
    id
    account
    debit
    credit
    user_id
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateProfitAndLossStatementSubscriptionVariables,
  APITypes.OnUpdateProfitAndLossStatementSubscription
>;
export const onDeleteProfitAndLossStatement = /* GraphQL */ `subscription OnDeleteProfitAndLossStatement(
  $filter: ModelSubscriptionProfitAndLossStatementFilterInput
  $user_id: String
) {
  onDeleteProfitAndLossStatement(filter: $filter, user_id: $user_id) {
    id
    account
    debit
    credit
    user_id
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteProfitAndLossStatementSubscriptionVariables,
  APITypes.OnDeleteProfitAndLossStatementSubscription
>;
export const onCreateCashFlowStatement = /* GraphQL */ `subscription OnCreateCashFlowStatement(
  $filter: ModelSubscriptionCashFlowStatementFilterInput
  $user_id: String
) {
  onCreateCashFlowStatement(filter: $filter, user_id: $user_id) {
    id
    type
    key
    value
    user_id
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateCashFlowStatementSubscriptionVariables,
  APITypes.OnCreateCashFlowStatementSubscription
>;
export const onUpdateCashFlowStatement = /* GraphQL */ `subscription OnUpdateCashFlowStatement(
  $filter: ModelSubscriptionCashFlowStatementFilterInput
  $user_id: String
) {
  onUpdateCashFlowStatement(filter: $filter, user_id: $user_id) {
    id
    type
    key
    value
    user_id
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateCashFlowStatementSubscriptionVariables,
  APITypes.OnUpdateCashFlowStatementSubscription
>;
export const onDeleteCashFlowStatement = /* GraphQL */ `subscription OnDeleteCashFlowStatement(
  $filter: ModelSubscriptionCashFlowStatementFilterInput
  $user_id: String
) {
  onDeleteCashFlowStatement(filter: $filter, user_id: $user_id) {
    id
    type
    key
    value
    user_id
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteCashFlowStatementSubscriptionVariables,
  APITypes.OnDeleteCashFlowStatementSubscription
>;
export const onCreateInvoice = /* GraphQL */ `subscription OnCreateInvoice(
  $filter: ModelSubscriptionInvoiceFilterInput
  $user_id: String
) {
  onCreateInvoice(filter: $filter, user_id: $user_id) {
    id
    title
    suppliers
    suppliers_address
    bussiness_information
    status
    charge_amount
    issue_date
    due_date
    send_offline
    send_online
    person
    assignee
    note
    account_information
    user_id
    items {
      nextToken
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateInvoiceSubscriptionVariables,
  APITypes.OnCreateInvoiceSubscription
>;
export const onUpdateInvoice = /* GraphQL */ `subscription OnUpdateInvoice(
  $filter: ModelSubscriptionInvoiceFilterInput
  $user_id: String
) {
  onUpdateInvoice(filter: $filter, user_id: $user_id) {
    id
    title
    suppliers
    suppliers_address
    bussiness_information
    status
    charge_amount
    issue_date
    due_date
    send_offline
    send_online
    person
    assignee
    note
    account_information
    user_id
    items {
      nextToken
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateInvoiceSubscriptionVariables,
  APITypes.OnUpdateInvoiceSubscription
>;
export const onDeleteInvoice = /* GraphQL */ `subscription OnDeleteInvoice(
  $filter: ModelSubscriptionInvoiceFilterInput
  $user_id: String
) {
  onDeleteInvoice(filter: $filter, user_id: $user_id) {
    id
    title
    suppliers
    suppliers_address
    bussiness_information
    status
    charge_amount
    issue_date
    due_date
    send_offline
    send_online
    person
    assignee
    note
    account_information
    user_id
    items {
      nextToken
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteInvoiceSubscriptionVariables,
  APITypes.OnDeleteInvoiceSubscription
>;
export const onCreateItem = /* GraphQL */ `subscription OnCreateItem(
  $filter: ModelSubscriptionItemFilterInput
  $user_id: String
) {
  onCreateItem(filter: $filter, user_id: $user_id) {
    id
    name
    item_code
    price
    ammount
    unit
    description
    tax_rate
    start_date
    period
    user_id
    invoice {
      id
      title
      suppliers
      suppliers_address
      bussiness_information
      status
      charge_amount
      issue_date
      due_date
      send_offline
      send_online
      person
      assignee
      note
      account_information
      user_id
      createdAt
      updatedAt
      __typename
    }
    createdAt
    updatedAt
    invoiceItemsId
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateItemSubscriptionVariables,
  APITypes.OnCreateItemSubscription
>;
export const onUpdateItem = /* GraphQL */ `subscription OnUpdateItem(
  $filter: ModelSubscriptionItemFilterInput
  $user_id: String
) {
  onUpdateItem(filter: $filter, user_id: $user_id) {
    id
    name
    item_code
    price
    ammount
    unit
    description
    tax_rate
    start_date
    period
    user_id
    invoice {
      id
      title
      suppliers
      suppliers_address
      bussiness_information
      status
      charge_amount
      issue_date
      due_date
      send_offline
      send_online
      person
      assignee
      note
      account_information
      user_id
      createdAt
      updatedAt
      __typename
    }
    createdAt
    updatedAt
    invoiceItemsId
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateItemSubscriptionVariables,
  APITypes.OnUpdateItemSubscription
>;
export const onDeleteItem = /* GraphQL */ `subscription OnDeleteItem(
  $filter: ModelSubscriptionItemFilterInput
  $user_id: String
) {
  onDeleteItem(filter: $filter, user_id: $user_id) {
    id
    name
    item_code
    price
    ammount
    unit
    description
    tax_rate
    start_date
    period
    user_id
    invoice {
      id
      title
      suppliers
      suppliers_address
      bussiness_information
      status
      charge_amount
      issue_date
      due_date
      send_offline
      send_online
      person
      assignee
      note
      account_information
      user_id
      createdAt
      updatedAt
      __typename
    }
    createdAt
    updatedAt
    invoiceItemsId
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteItemSubscriptionVariables,
  APITypes.OnDeleteItemSubscription
>;
