import GenericTemplate from "../templates/GenericTemplate";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Checkbox from "@material-ui/core/Checkbox";
import Button from "@material-ui/core/Button";
import Modal from "@mui/material/Modal";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Grid from "@material-ui/core/Grid";
import { ThemeProvider } from "@material-ui/styles";
import Box from "@material-ui/core/Box";
import { createMuiTheme } from "@material-ui/core/styles";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";

import DatePicker, { registerLocale } from "react-datepicker";
import ja from "date-fns/locale/ja";

import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

import React, { useEffect, useState } from "react";
import { API, graphqlOperation } from "aws-amplify";
import {
  listBalanceSheets,
  listBalanceSheetsByUser,
} from "../../graphql/queries";
import {
  createBalanceSheet,
  updateBalanceSheet,
  deleteBalanceSheet,
  updateUser,
} from "../../graphql/mutations";
import {
  onCreateBalanceSheet,
  onDeleteBalanceSheet,
  onUpdateBalanceSheet,
} from "../../graphql/subscriptions";
import {
  ListBalanceSheetsQuery,
  ListBalanceSheetsByUserQuery,
  OnCreateBalanceSheetSubscription,
  OnDeleteBalanceSheetSubscription,
  CreateBalanceSheetMutationVariables,
  UpdateBalanceSheetMutationVariables,
  DeleteBalanceSheetMutationVariables,
  OnUpdateBalanceSheetSubscription,
  UpdateUserMutationVariables,
} from "../../API";

import { DataGrid } from "@material-ui/data-grid";

import { getUserId } from "../../util";

import { useUser, User } from "../../util/user";

const getTotal = (data: BalanceSheet[], type: string) => {
  return data
    .filter((row: BalanceSheet) => row.type == type)
    .map((row: BalanceSheet) => row.value)
    .map((v: number | null | undefined) => (v ? v : 0))
    .reduce((prev: number, current: number) => prev + current, 0);
};

const getSector = (type: string) => {
  if (type === "ASSETS") {
    return "資産の部";
  } else if (type == "LIABILITIES") {
    return "負債の部";
  } else if (type === "NET_ASSETS") {
    return "純資産の部";
  } else {
    return "";
  }
};

function createData(
  name: string,
  calories: number,
  fat: number,
  carbs: number,
  protein: number
) {
  return { name, calories, fat, carbs, protein };
}
const rows = [
  createData("Frozen yoghurt", 159, 6.0, 24, 4.0),
  createData("Ice cream sandwich", 237, 9.0, 37, 4.3),
  createData("Eclair", 262, 16.0, 24, 6.0),
  createData("Cupcake", 305, 3.7, 67, 4.3),
  createData("Gingerbread", 356, 16.0, 49, 3.9),
];

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const theme = createMuiTheme({
  palette: {
    primary: { main: "#00838f" },
    secondary: { main: "#e0f7fa" },
  },
});

const onClickDeleteBalanceSheet = (id: string) => {
  const deletedBalanceSheet: DeleteBalanceSheetMutationVariables = {
    input: {
      id: id,
    },
  };
  API.graphql(graphqlOperation(deleteBalanceSheet, deletedBalanceSheet));
};

const EMPTY_STRING = "_";

const columns = [
  // { field: 'id', headerName: 'id', width: 90, editable: false },
  {
    field: "account",
    headerName: "日時",
    width: 240,
    editable: false,
    renderCell: (params: any) => {
      return <>{moment(params.value).format("YYYY/MM/DD")}</>;
      //   return (
      //     <DatePicker
      //       dateFormat="yyyy/MM/dd"
      //       locale="ja"
      //       selected={params.value}
      //       // minDate={Today}
      //       // onChange={onFormChange}
      //       onChange={(selectedDate) => {
      //         // setDate(selectedDate || Today);
      //       }}
      //     />
      //   );
    },
  },
  {
    field: "type",
    headerName: "タイプ",
    width: 180,
    editable: true,
    renderCell: (params: any) => {
      return <>{getSector(params.value)}</>;
      //   return (
      //     <DatePicker
      //       dateFormat="yyyy/MM/dd"
      //       locale="ja"
      //       selected={params.value}
      //       // minDate={Today}
      //       // onChange={onFormChange}
      //       onChange={(selectedDate) => {
      //         // setDate(selectedDate || Today);
      //       }}
      //     />
      //   );
    },
  },
  { field: "key", headerName: "科目", width: 180, editable: true },
  { field: "value", headerName: "金額", width: 180, editable: true },
  // {
  //     field: 'createdAt',
  //     headerName: '追加日',
  //     type: 'date',
  //     width: 240,
  //     editable: false,
  // },
  {
    field: "",
    headerName: "",
    width: 85,
    disableClickEventBubbling: true,
    renderCell: (params: any) => {
      const onClick = () => {
        onClickDeleteBalanceSheet(params.id);
        return;
      };

      return (
        <Button
          variant="contained"
          color="secondary"
          name="commit"
          onClick={onClick}
        >
          削除
        </Button>
      );
    },
  },
];

type FormState = {
  id: string;
  //   datetime: string;
  type?: string | null;
  key?: string | null;
  value?: number | null;
  user_id: string;
};

type BalanceSheet = FormState & {
  createdAt: string;
  updatedAt: string;
};

type BalanceSheetSubscriptionEventCreate = {
  value: { data: OnCreateBalanceSheetSubscription };
};
type BalanceSheetSubscriptionEventDelete = {
  value: { data: OnDeleteBalanceSheetSubscription };
};
type BalanceSheetSubscriptionEventUpdate = {
  value: { data: OnUpdateBalanceSheetSubscription };
};

const useBalanceSheets = () => {
  const [BalanceSheets, setBalanceSheets] = useState<BalanceSheet[]>([]);

  useEffect(() => {
    (async () => {
      let nextToken;
      let items: BalanceSheet[] = [];
      while (true) {
        const result = await API.graphql(
          graphqlOperation(listBalanceSheetsByUser, {
            user_id: getUserId(),
            nextToken: nextToken,
          })
        );
        if ("data" in result && result.data) {
          const newJournals = result.data as ListBalanceSheetsByUserQuery;

          if (newJournals.listBalanceSheetsByUser) {
            const tempItems = newJournals.listBalanceSheetsByUser
              .items as BalanceSheet[];
            items = items.concat(tempItems);
          }
          if (newJournals.listBalanceSheetsByUser?.nextToken) {
            nextToken = newJournals.listBalanceSheetsByUser?.nextToken;
          } else {
            setBalanceSheets(items as BalanceSheet[]);
            break;
          }
        }
      }

      const clientCreateBalanceSheet = API.graphql(
        graphqlOperation(onCreateBalanceSheet, {
          owner: getUserId(),
        })
      );
      if ("subscribe" in clientCreateBalanceSheet) {
        clientCreateBalanceSheet.subscribe({
          next: ({ value: { data } }: BalanceSheetSubscriptionEventCreate) => {
            if (data.onCreateBalanceSheet) {
              const balanceSheet: BalanceSheet = data.onCreateBalanceSheet;
              setBalanceSheets((prev) => [...prev, balanceSheet]);
            }
          },
        });
      }

      const clientDeleteBalanceSheet = API.graphql(
        graphqlOperation(onDeleteBalanceSheet, {
          owner: getUserId(),
        })
      );
      if ("subscribe" in clientDeleteBalanceSheet) {
        clientDeleteBalanceSheet.subscribe({
          next: ({ value: { data } }: BalanceSheetSubscriptionEventDelete) => {
            if (data.onDeleteBalanceSheet) {
              const balanceSheet: BalanceSheet = data.onDeleteBalanceSheet;
              setBalanceSheets((prev) => {
                return prev.filter((obj) => obj.id !== balanceSheet.id);
              });
            }
          },
        });
      }

      const clientUpdateBalanceSheet = API.graphql(
        graphqlOperation(onUpdateBalanceSheet, {
          owner: getUserId(),
        })
      );
      if ("subscribe" in clientUpdateBalanceSheet) {
        clientUpdateBalanceSheet.subscribe({
          next: ({ value: { data } }: BalanceSheetSubscriptionEventUpdate) => {
            if (data.onUpdateBalanceSheet) {
              const balanceSheet: BalanceSheet = data.onUpdateBalanceSheet;
              setBalanceSheets((prev) => [
                ...prev.filter((obj) => obj.id !== balanceSheet.id),
                balanceSheet,
              ]);
            }
          },
        });
      }
    })();
  }, []);

  const copiedNumbers = [...BalanceSheets];
  copiedNumbers.sort((a, b) => {
    if (a.key && b.key) {
      return a.key < b.key ? -1 : 1;
    } else {
      return 1;
    }
  });
  return copiedNumbers;
};

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
  root: {
    // flexGrow: 1,
    color: "#74eb34",
    backgroundColor: "#81c784",
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
    textAlign: "center",
  },
});

const ProductPage: React.FC = () => {
  const [input, setInput] = useState<FormState>({
    id: "",
    // datetime: "",
    type: "",
    key: "",
    value: 0,
    user_id: getUserId(),
  });
  const balanceSheets = useBalanceSheets();
  const [user, setUser] = useUser();

  const [filterNosummary, setFilterNosummary] = useState<boolean>();

  const onFormChange = ({
    target: { name, value },
  }: React.ChangeEvent<HTMLInputElement>) => {
    setInput((prev) => ({ ...prev, [name]: value }));
  };

  const onCreating = () => {
    // console.log(date);
    // if (input.datetime === "") return;

    const newBalanceSheet: CreateBalanceSheetMutationVariables = {
      input: {
        // datetime: moment(date).format(), //input.datetime,
        type: type, //input.type ? String(input.type) : "",
        key: input.key ? String(input.key) : "",
        value: input.value ? Number(input.value) : 0,
        user_id: getUserId(),
      },
    };
    console.log(input);
    setInput({
      id: "",
      type: "",
      key: "",
      value: 0,
      user_id: getUserId(),
    });
    API.graphql(graphqlOperation(createBalanceSheet, newBalanceSheet));
    setModal(false);
  };

  const onEditCellChangeCommitted = (e: any) => {
    console.log(e.field);
    const id = e.id;
    let input;
    if (e.field == "type") {
      input = {
        id: id,
        type: e.props.value ? String(e.props.value) : "",
      };
    } else if (e.field == "key") {
      input = {
        id: id,
        key: e.props.value ? String(e.props.value) : "",
      };
    } else if (e.field == "value") {
      input = {
        id: id,
        value: e.props.value ? Number(e.props.value) : 0,
      };
    } else {
      return;
    }
    console.log(input);

    const updatedBalanceSheet: UpdateBalanceSheetMutationVariables = {
      input: input,
    };
    API.graphql(graphqlOperation(updateBalanceSheet, updatedBalanceSheet));
  };

  const totalAssets = getTotal(balanceSheets, "ASSETS");
  const totalLiabilities = getTotal(balanceSheets, "LIABILITIES");
  const totalNetAssets = getTotal(balanceSheets, "NET_ASSETS");
  const totalLiabilitiesAndNetAssets = totalLiabilities + totalNetAssets;

  const lenAssets = balanceSheets.filter((row) => row.type == "ASSETS").length;
  const lenLiliabilities = balanceSheets.filter(
    (row) => row.type == "LIABILITIES"
  ).length;
  const lenNetAssets = balanceSheets.filter(
    (row) => row.type == "NET_ASSETS"
  ).length;

  const leftRowLength = lenAssets + 2;
  const rightRowLength = lenLiliabilities + lenNetAssets + 5;
  const targetRowLength =
    leftRowLength > rightRowLength ? leftRowLength : rightRowLength;
  const leftRowAdditionalLength = targetRowLength - leftRowLength;
  const leftRowAdditionalArray = [];
  for (let i = 0; i < leftRowAdditionalLength; i++) {
    leftRowAdditionalArray.push(i);
  }

  const rightRowAdditionalLength = targetRowLength - rightRowLength;
  const rightRowAdditionalArray = [];
  for (let i = 0; i < rightRowAdditionalLength; i++) {
    rightRowAdditionalArray.push(i);
  }

  const Today = new Date();
  const [date, setDate] = React.useState(Today);
  registerLocale("ja", ja);

  const [type, setType] = useState<string>("ASSETS");

  const handleChangeChecked = (event: React.ChangeEvent<HTMLInputElement>) => {
    console.log([event.target.name], event.target.checked);
    if (event.target.checked === true) {
      setType(event.target.name);
    }
  };
  const classes = useStyles();

  const [modal, setModal] = useState<boolean>(false);
  const [editMode, setEditMode] = useState<boolean>(false);

  const onClickEditable = async (e: any) => {
    setEditMode(true);
    console.log(e.target.parentNode.id);
    // return;
    // const result = await API.graphql(graphqlOperation(listBalanceSheets));
    // if ("data" in result && result.data) {
    //   const BalanceSheets = result.data as ListBalanceSheetsQuery;
    //   if (BalanceSheets.listBalanceSheets) {
    //     setBalanceSheets(
    //       BalanceSheets.listBalanceSheets.items as BalanceSheet[]
    //     );
    //   }
    // }

    console.log(e.target.parentNode.id);
    const targets = balanceSheets.filter((v) => v.id == e.target.parentNode.id);
    const target = targets[0];
    // setDate(new Date(moment(target.datetime).format()));
    // target.datetime = new Date(moment(target.datetime).utc);
    console.log(target);
    setInput(target);
    if (target.type) setType(target.type);
    setModal(true);
    //   if (!selectByRowClick) return;
    //   e.stopPropagation();
    //   onToggle();
  };

  const onUpdating = (e: any) => {
    // console.log(e.target.parentNode);
    const updatedBalanceSheet: UpdateBalanceSheetMutationVariables = {
      input: {
        id: input.id,
        // datetime: moment(date).format(), //input.datetime,
        type: type, //input.type ? String(input.type) : "",
        key: input.key ? String(input.key) : "",
        value: input.value ? Number(input.value) : 0,
      },
    };
    API.graphql(graphqlOperation(updateBalanceSheet, updatedBalanceSheet));
    setModal(false);
  };

  const onDeleting = (e: any) => {
    // console.log(e.target.parentNode);
    const deletedBalanceSheet: DeleteBalanceSheetMutationVariables = {
      input: {
        id: input.id,
      },
    };
    API.graphql(graphqlOperation(deleteBalanceSheet, deletedBalanceSheet));
    setModal(false);
  };

  const initModal = () => {
    setDate(Today);
    setEditMode(false);
    setInput({
      id: "",
      type: "",
      key: "",
      value: 0,
      user_id: getUserId(),
    });
    setModal(true);
  };

  const onChangeDate = (selectedDate: Date) => {
    //   console.log(selectedDate);
    // selectedDate || Today
    setUser((prevState: User) => {
      let jasper = Object.assign({}, prevState);
      jasper.datetime_balance_sheet = moment(selectedDate).format();
      return jasper;
    });
    const updatedUser: UpdateUserMutationVariables = {
      input: {
        id: getUserId(),
        datetime_balance_sheet: moment(selectedDate).format(),
      },
    };
    API.graphql(graphqlOperation(updateUser, updatedUser));
  };

  return (
    <GenericTemplate title="貸借対照表">
      <ThemeProvider theme={theme}>
        <Box>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div style={{ margin: "0 auto 10px 0" }}>
              <DatePicker
                //   className="customDatePicker"
                dateFormat="yyyy/MM/dd"
                locale="ja"
                selected={
                  user.datetime_balance_sheet
                    ? moment(user.datetime_balance_sheet).toDate()
                    : undefined
                }
                //   selected={new Date()}
                // minDate={Today}
                // onChange={onFormChange}
                onChange={onChangeDate}
              />
            </div>
            <div style={{ margin: "0 0 10px auto" }}>
              <TextField
                id="name"
                type="text"
                // label='表示名'
                style={{ width: 500 }}
                value={user.name}
              >
                {user.name}
              </TextField>
            </div>
          </div>
        </Box>

        <Modal
          open={modal}
          onClose={() => {
            setModal(false);
          }}
          //   aria-labelledby="modal-modal-title"
          //   aria-describedby="modal-modal-description"
        >
          <Box>
            <Box p={2} bgcolor="rgb(92, 44, 143);" color="primary.contrastText">
              {editMode ? "編集" : "新規作成"}
            </Box>

            <Box p={2} bgcolor="secondary.main" color="rgb(92, 44, 143);">
              <form action="/users" acceptCharset="UTF-8" method="post">
                {/* <div>
                  <DatePicker
                    className="customDatePicker"
                    dateFormat="yyyy/MM/dd"
                    locale="ja"
                    selected={date}
                    // minDate={Today}
                    // onChange={onFormChange}
                    onChange={(selectedDate) => {
                      //   console.log(selectedDate);
                      setDate(selectedDate || Today);
                    }}
                  />
                </div> */}
                <div
                  style={{
                    border: "1px solid",
                    marginTop: "10px",
                    padding: "3px",
                    width: 500,
                  }}
                >
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox
                          //   className={classes.root}
                          color="default"
                          checked={type === "ASSETS"}
                          onChange={handleChangeChecked}
                          name="ASSETS"
                        />
                      }
                      label="資産の部"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          color="default"
                          checked={type === "LIABILITIES"}
                          onChange={handleChangeChecked}
                          name="LIABILITIES"
                        />
                      }
                      label="負債の部"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          color="default"
                          checked={type === "NET_ASSETS"}
                          onChange={handleChangeChecked}
                          name="NET_ASSETS"
                        />
                      }
                      label="純資産の部"
                    />
                  </FormGroup>
                </div>

                <div>
                  <TextField
                    id="key"
                    type="text"
                    name="key"
                    label="科目"
                    style={{ width: 500 }}
                    value={input.key}
                    onChange={onFormChange}
                  />
                </div>
                <div>
                  <TextField
                    id="value"
                    type="text"
                    name="value"
                    label="金額"
                    style={{ width: 500 }}
                    value={input.value}
                    onChange={onFormChange}
                  />
                </div>
                <div style={{ marginTop: "10px" }}>
                  <Button
                    variant="contained"
                    color="inherit"
                    name="commit"
                    onClick={editMode ? onUpdating : onCreating}
                  >
                    {editMode ? "変更" : "保存"}
                  </Button>
                  {editMode ? (
                    <Button
                      style={{ marginLeft: "10px" }}
                      variant="contained"
                      color="default"
                      name="commit"
                      onClick={onDeleting}
                    >
                      削除
                    </Button>
                  ) : null}
                </div>
              </form>
            </Box>
          </Box>
        </Modal>
      </ThemeProvider>

      <div style={{ height: 300, width: "100%" }}>
        <Grid container justify="flex-end" style={{ paddingBottom: "10px" }}>
          <Button
            variant="contained"
            color="inherit"
            name="commit"
            onClick={initModal}
          >
            新規追加
          </Button>
        </Grid>

        {/* <DataGrid
          rows={balanceSheets}
          columns={columns}
          //   onCellClick={(params: any, event: any) => {
          //     console.log(params, event);
          //     event.defaultMuiPrevented = true;
          //   }}
          onEditCellChangeCommitted={onEditCellChangeCommitted}
          // checkboxSelection
          sortModel={[
            {
              field: "key",
              sort: "asc",
            },
          ]}
        /> */}

        <div>
          <Grid container spacing={0}>
            <Grid item xs={6} md={6}>
              <Box
                style={{ textAlign: "center" }}
                p={2}
                bgcolor="rgb(92, 44, 143);"
                color="primary.contrastText"
              >
                資産の部 　{" "}
              </Box>
              <Grid style={{ width: "100%" }}>
                <TableContainer component={Paper} style={{ borderRadius: 0 }}>
                  <Table sx={{ minWidth: 0 }} aria-label="customized table">
                    <TableBody>
                      {balanceSheets
                        .filter((row) => row.type == "ASSETS")
                        .map((row) => (
                          <StyledTableRow
                            id={row.id}
                            key={row.key}
                            onClick={onClickEditable}
                          >
                            <StyledTableCell component="th" scope="row">
                              {row.key}
                            </StyledTableCell>
                            <StyledTableCell align="right">
                              {row.value}
                            </StyledTableCell>
                          </StyledTableRow>
                        ))}
                      {leftRowAdditionalArray.map((row) => (
                        <StyledTableRow key={row}>
                          <StyledTableCell component="th" scope="row">
                            {EMPTY_STRING}
                          </StyledTableCell>
                          <StyledTableCell align="right">
                            {EMPTY_STRING}
                          </StyledTableCell>
                        </StyledTableRow>
                      ))}
                      <StyledTableRow key={"資産の部の合計"}>
                        <StyledTableCell
                          component="th"
                          scope="row"
                          style={{ fontWeight: "bold" }}
                        >
                          {"資産の部の合計"}
                        </StyledTableCell>
                        <StyledTableCell
                          align="right"
                          style={{ fontWeight: "bold" }}
                        >
                          {totalAssets}
                        </StyledTableCell>
                      </StyledTableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
            </Grid>

            <Grid item xs={6} md={6}>
              <Box
                style={{ textAlign: "center" }}
                p={2}
                bgcolor="rgb(92, 44, 143);"
                color="primary.contrastText"
              >
                負債の部 　{" "}
              </Box>
              <Grid style={{ width: "100%" }}>
                <TableContainer component={Paper} style={{ borderRadius: 0 }}>
                  <Table sx={{ minWidth: 0 }} aria-label="customized table">
                    <TableBody>
                      {balanceSheets
                        .filter((row) => row.type == "LIABILITIES")
                        .map((row) => (
                          <StyledTableRow
                            id={row.id}
                            key={row.key}
                            onClick={onClickEditable}
                          >
                            <StyledTableCell component="th" scope="row">
                              {row.key}
                            </StyledTableCell>
                            <StyledTableCell align="right">
                              {row.value}
                            </StyledTableCell>
                          </StyledTableRow>
                        ))}
                      <StyledTableRow key={"負債の部の合計"}>
                        <StyledTableCell
                          component="th"
                          scope="row"
                          style={{ fontWeight: "bold" }}
                        >
                          {"負債の部の合計"}
                        </StyledTableCell>
                        <StyledTableCell
                          align="right"
                          style={{ fontWeight: "bold" }}
                        >
                          {totalLiabilities}
                        </StyledTableCell>
                      </StyledTableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>

              <Box
                style={{
                  textAlign: "center",
                  border: "solid 1px blue",
                  //   padding: "1px",
                }}
                p={2}
                bgcolor="rgb(92, 44, 143);"
                color="primary.contrastText"
              >
                純資産の部 　{" "}
              </Box>

              <Grid style={{ height: 0, width: "100%" }}>
                <TableContainer component={Paper} style={{ borderRadius: 0 }}>
                  <Table sx={{ minWidth: 0 }} aria-label="customized table">
                    <TableBody>
                      {balanceSheets
                        .filter((row) => row.type == "NET_ASSETS")
                        .map((row) => (
                          <StyledTableRow
                            id={row.id}
                            key={row.key}
                            onClick={onClickEditable}
                          >
                            <StyledTableCell component="th" scope="row">
                              {row.key}
                            </StyledTableCell>
                            <StyledTableCell align="right">
                              {row.value}
                            </StyledTableCell>
                          </StyledTableRow>
                        ))}
                      {rightRowAdditionalArray.map((row) => (
                        <StyledTableRow key={row}>
                          <StyledTableCell component="th" scope="row">
                            {EMPTY_STRING}
                          </StyledTableCell>
                          <StyledTableCell align="right">
                            {EMPTY_STRING}
                          </StyledTableCell>
                        </StyledTableRow>
                      ))}
                      <StyledTableRow key={"純資産の部の合計"}>
                        <StyledTableCell
                          component="th"
                          scope="row"
                          style={{ fontWeight: "bold" }}
                        >
                          {"純資産の部の合計"}
                        </StyledTableCell>
                        <StyledTableCell
                          align="right"
                          style={{ fontWeight: "bold" }}
                        >
                          {totalNetAssets}
                        </StyledTableCell>
                      </StyledTableRow>
                      <StyledTableRow key={"負債・純資産の部の合計"}>
                        <StyledTableCell
                          component="th"
                          scope="row"
                          style={{ fontWeight: "bold" }}
                        >
                          {"負債・純資産の部の合計"}
                        </StyledTableCell>
                        <StyledTableCell
                          align="right"
                          style={{ fontWeight: "bold" }}
                        >
                          {totalLiabilitiesAndNetAssets}
                        </StyledTableCell>
                      </StyledTableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
            </Grid>
          </Grid>
        </div>
      </div>

      {/* <div></div> */}
    </GenericTemplate>
  );
};

export default ProductPage;
