import React from "react";
import clsx from "clsx";
import { createMuiTheme } from "@material-ui/core/styles";
import * as colors from "@material-ui/core/colors";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import Drawer from "@material-ui/core/Drawer";
import Box from "@material-ui/core/Box";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import List from "@material-ui/core/List";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import Container from "@material-ui/core/Container";
import { Link } from "react-router-dom";
import MenuIcon from "@material-ui/icons/Menu";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import IconButton from "@material-ui/core/IconButton";
import Flag from "@material-ui/icons/Flag";
import Grade from "@material-ui/icons/Grade";
import Amplify, { API, Auth } from "aws-amplify";
// import { Label } from "@material-ui/icons";
import { useUser, User } from "../../util/user";

import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";

const drawerWidth = 270;

const theme = createMuiTheme({
  typography: {
    fontFamily: [
      "Noto Sans JP",
      "Lato",
      "游ゴシック Medium",
      "游ゴシック体",
      "Yu Gothic Medium",
      "YuGothic",
      "ヒラギノ角ゴ ProN",
      "Hiragino Kaku Gothic ProN",
      "メイリオ",
      "Meiryo",
      "ＭＳ Ｐゴシック",
      "MS PGothic",
      "sans-serif",
    ].join(","),
  },
  palette: {
    primary: { main: colors.blue[800] }, // テーマの色
  },
});

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
    },
    toolbar: {
      paddingRight: 24,
      backgroundColor: "rgb(92, 44, 143);",
    },
    toolbarIcon: {
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-end",
      padding: "0 8px",
      ...theme.mixins.toolbar,
    },
    appBar: {
      zIndex: theme.zIndex.drawer + 1,
      transition: theme.transitions.create(["width", "margin"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
    appBarShift: {
      marginLeft: drawerWidth,
      width: `calc(100% - ${drawerWidth}px)`,
      transition: theme.transitions.create(["width", "margin"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    menuButton: {
      marginRight: 36,
    },
    menuButtonHidden: {
      display: "none",
    },
    title: {
      flexGrow: 1,
    },
    pageTitle: {
      marginBottom: theme.spacing(1),
    },
    drawerPaper: {
      position: "relative",
      whiteSpace: "nowrap",
      width: drawerWidth,
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    drawerPaperClose: {
      overflowX: "hidden",
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      width: theme.spacing(7),
      [theme.breakpoints.up("sm")]: {
        width: theme.spacing(9),
      },
    },
    appBarSpacer: theme.mixins.toolbar,
    content: {
      flexGrow: 1,
      height: "100vh",
      overflow: "auto",
    },
    container: {
      paddingTop: theme.spacing(4),
      paddingBottom: theme.spacing(4),
    },
    paper: {
      padding: theme.spacing(2),
      display: "flex",
      overflow: "auto",
      flexDirection: "column",
    },
    link: {
      textDecoration: "none",
      color: theme.palette.text.secondary,
    },
  })
);

const Copyright = () => {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {"Copyright © "}
      <Link color="inherit" to="/">
        彗星会計
        {/* <img src={'/logo.png'}/> */}
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
};

export interface GenericTemplateProps {
  children: React.ReactNode;
  title: string;
}

const GenericTemplate: React.FC<GenericTemplateProps> = ({
  children,
  title,
}) => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(true);
  const handleDrawerOpen = () => {
    setOpen(true);
  };
  const handleDrawerClose = () => {
    setOpen(false);
  };
  const [user, setUser] = useUser();

  return (
    <ThemeProvider theme={theme}>
      <div className={classes.root}>
        <CssBaseline />
        <AppBar
          position="absolute"
          className={clsx(classes.appBar, open && classes.appBarShift)}
        >
          <Toolbar className={classes.toolbar}>
            <IconButton
              edge="start"
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              className={clsx(
                classes.menuButton,
                open && classes.menuButtonHidden
              )}
            >
              <MenuIcon />
            </IconButton>
            <Typography
              component="h1"
              variant="h6"
              color="inherit"
              noWrap
              className={classes.title}
              // style={{padding: '5px 0 5px 0'}}
            >
              彗星会計（インボイス制度対応版）
              {/* <img style={{maxHeight:'55px', margin: '10px 0 auto 0', bottom: '0'}} src={'/logo.png'}/> */}
            </Typography>
            <Typography component="h1" variant="h6" color="inherit" noWrap>
              {user.name}
              {/* <img style={{maxHeight:'55px', margin: '10px 0 auto 0', bottom: '0'}} src={'/logo.png'}/> */}
            </Typography>
          </Toolbar>
        </AppBar>
        <Drawer
          variant="permanent"
          classes={{
            paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose),
          }}
          open={open}
        >
          <div className={classes.toolbarIcon}>
            <IconButton onClick={handleDrawerClose}>
              <ChevronLeftIcon />
            </IconButton>
          </div>

          <Divider />
          <Typography style={{ margin: "0 0 0 10px" }}>注文</Typography>
          <List>
            <Link to="/invoice" className={classes.link}>
              <ListItem button>
                <ListItemIcon>
                  <Grade />
                </ListItemIcon>
                <ListItemText primary="注文一覧" />
              </ListItem>
            </Link>
            <Link to="/exported-invoice/new" className={classes.link}>
              <ListItem button>
                <ListItemIcon>
                  <Grade />
                </ListItemIcon>
                <ListItemText primary="請求書発行" />
              </ListItem>
            </Link>
          </List>

          <Divider />
          <Typography style={{ margin: "0 0 0 10px" }}>会計</Typography>
          <List>
            <Link to="/journal" className={classes.link}>
              <ListItem button>
                <ListItemIcon>
                  <Grade />
                </ListItemIcon>
                <ListItemText primary="仕訳帳" />
              </ListItem>
            </Link>
          </List>
          <List>
            <Link to="/cash-register" className={classes.link}>
              <ListItem button>
                <ListItemIcon>
                  <Grade />
                </ListItemIcon>
                <ListItemText primary="現金出納帳" />
              </ListItem>
            </Link>
          </List>
          <List>
            <Link to="/general-ledger" className={classes.link}>
              <ListItem button>
                <ListItemIcon>
                  <Grade />
                </ListItemIcon>
                <ListItemText primary="総勘定元帳" />
              </ListItem>
            </Link>
          </List>
          <List>
            <Link to="/trial-balance" className={classes.link}>
              <ListItem button>
                <ListItemIcon>
                  <Grade />
                </ListItemIcon>
                <ListItemText primary="残高試算表" />
              </ListItem>
            </Link>
          </List>
          <List>
            <Link to="/balance-sheet" className={classes.link}>
              <ListItem button>
                <ListItemIcon>
                  <Grade />
                </ListItemIcon>
                <ListItemText primary="貸借対照表" />
              </ListItem>
            </Link>
          </List>
          <List>
            <Link to="/profit-and-loss-statement" className={classes.link}>
              <ListItem button>
                <ListItemIcon>
                  <Grade />
                </ListItemIcon>
                <ListItemText primary="損益計算書" />
              </ListItem>
            </Link>
          </List>
          <List>
            <Link to="/cash-flow-statement" className={classes.link}>
              <ListItem button>
                <ListItemIcon>
                  <Grade />
                </ListItemIcon>
                <ListItemText primary="キャッシュフロー計算書" />
              </ListItem>
            </Link>
          </List>

          <Divider />
          <List>
            <List>
              <Link to="/settings" className={classes.link}>
                <ListItem button>
                  <ListItemIcon>
                    <Grade />
                  </ListItemIcon>
                  <ListItemText primary="ユーザー設定" />
                </ListItem>
              </Link>
            </List>
          </List>
          <a href="/" className={classes.link}>
            <ListItem button>
              <ListItemIcon>
                <Grade />
              </ListItemIcon>
              <ListItemText primary="オンラインマニュアル" />
            </ListItem>
          </a>
          <Divider />
          <List
            onClick={() => {
              Auth.signOut();
            }}
          >
            <ListItem button>
              <ListItemIcon>
                <Grade />
              </ListItemIcon>
              <ListItemText primary="ログアウト" />
            </ListItem>
          </List>
        </Drawer>
        <main className={classes.content}>
          <div className={classes.appBarSpacer} />
          <Container maxWidth="lg" className={classes.container}>
            <Typography
              component="h2"
              variant="h5"
              color="inherit"
              noWrap
              className={classes.pageTitle}
            >
              {title}
            </Typography>
            {children}
            {/* <Box pt={4}>
              <Copyright />
            </Box> */}
          </Container>
        </main>
      </div>
    </ThemeProvider>
  );
};

export default GenericTemplate;
