import React, { useEffect, useState } from "react";
import { API, graphqlOperation } from "aws-amplify";

import moment from "moment";
import { getUserId } from "../util";

import { listJournals, getUser } from "../graphql/queries";
import {
  createUser,
} from "../graphql/mutations";
import {
    onCreateUser,
    onDeleteUser,
    onUpdateUser,
} from "../graphql/subscriptions";
import {
  GetUserQuery,
  GetUserQueryVariables,
  CreateUserMutationVariables,
  OnUpdateUserSubscription,
} from "../API";

type UserSubscriptionEventUpdate = {
    value: { data: OnUpdateUserSubscription };
};

type UserBase = {
    id: string;
    name?: string | null;
    datetime_journal?: string | null;
    datetime_cash_register?: string | null;
    datetime_general_ledger?: string | null;
    datetime_trial_balance?: string | null;
    datetime_balance_sheet?: string | null;
    datetime_since_profit_and_loss_statement?: string | null;
    datetime_to_profit_and_loss_statement?: string | null;
    datetime_since_cash_flow_statement?: string | null;
    datetime_to_cash_flow_statement?: string | null;
    start_balance_cash_flow_statement?: number | null;
  };
  
  export type User = UserBase & {
    createdAt: string;
    updatedAt: string;
  };
  
  export const useUser: any = () => {
    const now = moment(new Date()).format();
    const [user, setUser] = useState<User>({
      id: getUserId(),
      name: null,
      datetime_journal: null,
      datetime_cash_register: null,
      datetime_general_ledger: null,
      datetime_trial_balance: null,
      datetime_balance_sheet: null,
      datetime_since_profit_and_loss_statement: null,
      datetime_to_profit_and_loss_statement: null,
      datetime_since_cash_flow_statement: null,
      datetime_to_cash_flow_statement: null,
      start_balance_cash_flow_statement: 0,
      createdAt: now,
      updatedAt: now,
    });
  
    useEffect(() => {
      (async () => {
        const creatingUser: CreateUserMutationVariables = {
          input: {
            id: getUserId(),
            name: "",
            datetime_journal: moment(new Date()).format(),
            datetime_cash_register: moment(new Date()).format(),
            datetime_general_ledger: moment(new Date()).format(),
            datetime_trial_balance: moment(new Date()).format(),
            datetime_balance_sheet: moment(new Date()).format(),
            datetime_since_profit_and_loss_statement: moment(new Date()).format(),
            datetime_to_profit_and_loss_statement: moment(new Date()).format(),
            datetime_since_cash_flow_statement: moment(new Date()).format(),
            datetime_to_cash_flow_statement: moment(new Date()).format(),
            start_balance_cash_flow_statement: 0,
            //   createdAt: moment(new Date()).format(),
            //   updatedAt: moment(new Date()).format(),
          },
        };
        try {
          const createUserResult = await API.graphql(
            graphqlOperation(createUser, creatingUser)
          );
        } catch (e) {}
  
        //   console.log(createUserResult);
  
        const gettingUser: GetUserQueryVariables = {
          id: getUserId(),
        };
        const getUserResult = await API.graphql(
          graphqlOperation(getUser, gettingUser)
        );
        // console.log(getUserResult);
        if ("data" in getUserResult && getUserResult.data) {
          const user = getUserResult.data as GetUserQuery;
          if (user.getUser) {
            setUser(user.getUser as User);
          }
        }

        const clientUpdateUser = API.graphql(
            graphqlOperation(onUpdateUser, {
              owner: getUserId(),
            })
          );
        if ("subscribe" in clientUpdateUser) {
            clientUpdateUser.subscribe({
                next: ({ value: { data } }: UserSubscriptionEventUpdate) => {
                if (data.onUpdateUser) {
                    const _user: User = data.onUpdateUser;
                    // if (_user.datetime_journal){
                        setUser((prevState: User) => {
                            let obj = Object.assign({}, prevState);
                            obj.name = _user.name
                            obj.datetime_journal = moment(_user.datetime_journal).format();
                            obj.datetime_cash_register = moment(_user.datetime_cash_register).format();
                            obj.datetime_general_ledger = moment(_user.datetime_general_ledger).format();
                            obj.datetime_trial_balance = moment(_user.datetime_trial_balance).format();
                            obj.datetime_balance_sheet = moment(_user.datetime_balance_sheet).format();
                            obj.datetime_since_profit_and_loss_statement = moment(_user.datetime_since_profit_and_loss_statement).format();
                            obj.datetime_to_profit_and_loss_statement = moment(_user.datetime_to_profit_and_loss_statement).format();
                            obj.datetime_since_cash_flow_statement = moment(_user.datetime_since_cash_flow_statement).format();
                            obj.datetime_to_cash_flow_statement = moment(_user.datetime_to_cash_flow_statement).format();
                            obj.datetime_to_cash_flow_statement = _user.datetime_to_cash_flow_statement;
                            return obj;
                        });
                    // }

                }
                },
            });
        }



      })();
    }, []);
  
    return [user, setUser];
  };