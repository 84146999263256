import React, { useEffect, useState } from "react";
import { API, graphqlOperation } from "aws-amplify";

import moment from "moment";
import { getUserId } from "../util";

import { listJournals, getInvoice } from "../graphql/queries";
import {
  createInvoice,
} from "../graphql/mutations";
import {
    onCreateInvoice,
    onDeleteInvoice,
    onUpdateInvoice,
} from "../graphql/subscriptions";
import {
  GetInvoiceQuery,
  GetInvoiceQueryVariables,
  CreateInvoiceMutationVariables,
  OnUpdateInvoiceSubscription,
} from "../API";

const getInvoiceId = () => {
  const url = new URL(window.location.href)
  const id = url.pathname.split('/')[2]
  return id
}

type InvoiceSubscriptionEventUpdate = {
    value: { data: OnUpdateInvoiceSubscription };
};

type InvoiceBase = {
    id: string;
    title?: string | null;
    suppliers?: string | null;
    suppliers_address?: string | null;
    bussiness_information?: string | null;
    status?: string | null;
    charge_amount?: number | null;
    issue_date?: string | null;
    due_date?: string | null;
    send_offline?: string | null;
    send_online?: string | null;
    person?: string | null;
    assignee?: string | null;
    account_information?: string | null;
    items?: any;
    note?: string | null;
    total_ammount?: number | null;
    user_id: string;
  };
  
  export type Invoice = InvoiceBase & {
    createdAt: string;
    updatedAt: string;
  };
  
  export const useInvoice: any = () => {
    const now = moment(new Date()).format();
    const [invoice, setInvoice] = useState<Invoice>({
      id: "",
      title: "",
      suppliers: "",
      suppliers_address: "",
      bussiness_information: "",
      status: "",
      charge_amount: 0,
      issue_date: "",
      due_date: "",
      send_offline: "",
      send_online: "",
      person: "",
      assignee: "",
      account_information: "",
      note: "",
      items: undefined,
      total_ammount: 0,
      user_id: getUserId(),
      createdAt: now,
      updatedAt: now,
    });
    const [Invoices, setInvoices] = useState<Invoice[]>([]);
  
    useEffect(() => {
      (async () => {
        const creatingInvoice: CreateInvoiceMutationVariables = {
          input: {
            id: "",
            title: "",
            suppliers: "",
            suppliers_address: "",
            bussiness_information: "",
            status: "",
            charge_amount: 0,
            issue_date: "",
            due_date: "",
            send_offline: "",
            send_online: "",
            person: "",
            assignee: "",
            account_information: "",
            note: "",
            // items: undefined,
            // total_ammount: 0,
            user_id: getUserId(),
            // createdAt: now,
            // updatedAt: now,
            //   createdAt: moment(new Date()).format(),
            //   updatedAt: moment(new Date()).format(),
          },
        };
        try {
          const createInvoiceResult = await API.graphql(
            graphqlOperation(createInvoice, creatingInvoice)
          );
        } catch (e) {}
  
        //   console.log(createInvoiceResult);
  
        const gettingInvoice: GetInvoiceQueryVariables = {
          id: getInvoiceId(),
        };
        const getInvoiceResult = await API.graphql(
          graphqlOperation(getInvoice, gettingInvoice)
        );
        // console.log(getInvoiceResult);
        if ("data" in getInvoiceResult && getInvoiceResult.data) {
          const invoice = getInvoiceResult.data as GetInvoiceQuery;
          if (invoice.getInvoice) {
            setInvoice(invoice.getInvoice as Invoice);
            // calcTotalAmount(invoice.getInvoice as Invoice, setInvoice)
          }
        }

        const clientUpdateInvoice = API.graphql(
            graphqlOperation(onUpdateInvoice, {
              owner: getUserId(),
            })
          );
        if ("subscribe" in clientUpdateInvoice) {
            clientUpdateInvoice.subscribe({
                next: ({ value: { data } }: InvoiceSubscriptionEventUpdate) => {
                if (data.onUpdateInvoice) {
                    const invoice: Invoice = data.onUpdateInvoice;
                    setInvoices((prev) => [
                      ...prev.filter((obj) => obj.id !== invoice.id),
                      invoice,
                    ]);

                }
                },
            });
        }



      })();
    }, []);
  
    return [invoice, setInvoice];
  };