import GenericTemplate from "../templates/GenericTemplate";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import { ThemeProvider } from "@material-ui/styles";
import Box from "@material-ui/core/Box";
import { createMuiTheme } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Modal from "@mui/material/Modal";
// import {
//   Page,
//   Text,
//   View,
//   Document,
//   StyleSheet,
//   PDFViewer,
//   PDFDownloadLink,
// } from "@react-pdf/renderer";
// import ReactPDF from "@react-pdf/renderer";
// import ReactDOM from "react-dom";
// import { jsPDF } from "jspdf";

import moment from "moment";
import DatePicker, { registerLocale } from "react-datepicker";
import ja from "date-fns/locale/ja";

import React, { useEffect, useState } from "react";
import { API, graphqlOperation } from "aws-amplify";
import { listItems, getUser, listItemsByUser } from "../../graphql/queries";
import {
  createUser,
  createItem,
  updateItem,
  deleteItem,
  updateUser,
} from "../../graphql/mutations";
import {
  onCreateItem,
  onDeleteItem,
  onUpdateItem,
} from "../../graphql/subscriptions";
import {
  GetUserQuery,
  GetUserQueryVariables,
  CreateUserMutationVariables,
  UpdateUserMutationVariables,
  ListItemsQuery,
  ListItemsByUserQuery,
  OnCreateItemSubscription,
  OnDeleteItemSubscription,
  CreateItemMutationVariables,
  UpdateItemMutationVariables,
  DeleteItemMutationVariables,
  OnUpdateItemSubscription,
} from "../../API";

import { DataGrid } from "@material-ui/data-grid";

import { getUserId } from "../../util";

import { useUser, User } from "../../util/user";
// import { Invoice } from "../pages/InvoicePage";

import { useInvoice, Invoice } from "../../util/invoice";
import { Label } from "@material-ui/icons";

const theme = createMuiTheme({
  palette: {
    primary: { main: "#00838f" },
    secondary: { main: "#e0f7fa" },
  },
});

const onClickDeleteItem = (id: string) => {
  const deletedItem: DeleteItemMutationVariables = {
    input: {
      id: id,
    },
  };
  API.graphql(graphqlOperation(deleteItem, deletedItem));
};

const getInvoiveId = () => {
  const url = new URL(window.location.href);
  const id = url.pathname.split("/")[2];
  return id;
  // return  {
  //   id: id,
  //   user_id: getUserId(),
  //   createdAt: "",
  //   updatedAt: "",
  // }
};

const columns = [
  // { field: 'id', headerName: 'id', width: 90, editable: false },
  { field: "name", headerName: "商品名", width: 180, editable: true },
  { field: "price", headerName: "単価", width: 110, editable: true },
  { field: "ammount", headerName: "数量", width: 110, editable: true },
  // { field: "unit", headerName: "単位", width: 110, editable: true },
  // { field: "description", headerName: "詳細", width: 110, editable: true },
  { field: "tax_rate", headerName: "税率(%)", width: 150, editable: true },
  {
    field: "start_date",
    headerName: "開始日",
    width: 150,
    editable: true,
    renderCell: (params: any) => {
      return <div>{moment(params.value).format("YYYY/MM/DD")}</div>;
    },
  },
  {
    field: "tax_",
    headerName: "軽減税率適応可否",
    width: 200,
    editable: false,
    renderCell: (params: any) => {
      // console.log(params);
      return <div>{params.row.tax_rate === 10 ? "適応" : "適応外"}</div>;
    },
  },

  // { field: "period", headerName: "期間", width: 180, editable: true },
  // { field: "user_id", headerName: "送信", width: 180, editable: true },

  // { field: "person", headerName: "担当者", width: 180, editable: true },
  // {
  //   field: "invoiceItemsId",
  //   headerName: "請求書ID",
  //   width: 180,
  //   editable: false,
  // },
  // { field: "user_id", headerName: "貸方", width: 180, editable: true },
  // {
  //     field: 'createdAt',
  //     headerName: '追加日',
  //     type: 'date',
  //     width: 240,
  //     editable: false,
  // },
  {
    field: "",
    headerName: "",
    width: 85,
    disableClickEventBubbling: true,
    renderCell: (params: any) => {
      const onClick = () => {
        onClickDeleteItem(params.id);
        return;
      };

      return (
        <Button
          variant="contained"
          color="secondary"
          name="commit"
          onClick={onClick}
        >
          削除
        </Button>
      );
    },
  },
];

type FormState = {
  id: string;
  name?: string | null;
  price?: number | null;
  ammount?: number | null;
  unit?: string | null;
  description?: string | null;
  tax_rate?: number | null;
  start_date?: string | null;
  period?: string | null;
  user_id: string;
  invoice: Invoice;
  invoiceItemsId?: string | null;
};

type Item = FormState & {
  createdAt: string;
  updatedAt: string;
};

type ItemSubscriptionEventCreate = {
  value: { data: OnCreateItemSubscription };
};
type ItemSubscriptionEventDelete = {
  value: { data: OnDeleteItemSubscription };
};
type ItemSubscriptionEventUpdate = {
  value: { data: OnUpdateItemSubscription };
};

const useItems = () => {
  const [Items, setItems] = useState<Item[]>([]);

  useEffect(() => {
    (async () => {
      let nextToken;
      let _items: Item[] = [];
      while (true) {
        const result = await API.graphql(
          graphqlOperation(listItemsByUser, {
            user_id: getUserId(),
            // filter: { invoiceItemsId: { eq: getInvoiveId() } },
            nextToken: nextToken,
          })
        );
        if ("data" in result && result.data) {
          const Items = result.data as ListItemsByUserQuery;
          if (Items.listItemsByUser) {
            _items = _items.concat(Items.listItemsByUser.items as Item[]);
          }

          if (Items.listItemsByUser?.nextToken) {
            nextToken = Items.listItemsByUser?.nextToken;
          } else {
            setItems(_items as Item[]);
            break;
          }
        }
      }

      const clientCreateItem = API.graphql(
        graphqlOperation(onCreateItem, {
          owner: getUserId(),
        })
      );
      if ("subscribe" in clientCreateItem) {
        clientCreateItem.subscribe({
          next: ({ value: { data } }: ItemSubscriptionEventCreate) => {
            if (data.onCreateItem) {
              const item: Item = data.onCreateItem;
              if (item.invoiceItemsId !== getInvoiveId()) return;
              setItems((prev) => [...prev, item]);
            }
          },
        });
      }

      const clientDeleteItem = API.graphql(
        graphqlOperation(onDeleteItem, {
          owner: getUserId(),
        })
      );
      if ("subscribe" in clientDeleteItem) {
        clientDeleteItem.subscribe({
          next: ({ value: { data } }: ItemSubscriptionEventDelete) => {
            if (data.onDeleteItem) {
              const item: Item = data.onDeleteItem;
              if (item.invoiceItemsId !== getInvoiveId()) return;
              setItems((prev) => {
                return prev.filter((obj) => obj.id !== item.id);
              });
            }
          },
        });
      }

      const clientUpdateItem = API.graphql(
        graphqlOperation(onUpdateItem, {
          owner: getUserId(),
        })
      );
      if ("subscribe" in clientUpdateItem) {
        clientUpdateItem.subscribe({
          next: ({ value: { data } }: ItemSubscriptionEventUpdate) => {
            if (data.onUpdateItem) {
              const item: Item = data.onUpdateItem;
              if (item.invoiceItemsId !== getInvoiveId()) return;
              setItems((prev) => [
                ...prev.filter((obj) => obj.id !== item.id),
                item,
              ]);
            }
          },
        });
      }
    })();
  }, []);

  return Items;
};

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
});

// var sum = function (nums: Array<number>) {
//   return nums.reduce(function (a: number, x: number) {
//     return a + x;
//   });
// };

const ProductPage: React.FC = () => {
  const [input, setInput] = useState<FormState>({
    id: "",
    name: "",
    price: 0,
    ammount: 0,
    unit: "",
    description: "",
    tax_rate: 10,
    start_date: moment(new Date()).format(),
    period: "",
    user_id: getUserId(),
    invoice: {
      id: "",
      user_id: getUserId(),
      createdAt: "",
      updatedAt: "",
    },
  });

  const items = useItems();
  const [user, setUser] = useUser();
  const [invoice, setInvoice] = useInvoice();

  const [filterNosummary, setFilterNosummary] = useState<boolean>();

  const dataObject = {
    items: items,
    user: user,
    invoice: invoice,
  };
  // console.log(JSON.stringify(dataObject));

  const onFormChange = ({
    target: { name, value },
  }: React.ChangeEvent<HTMLInputElement>) => {
    setInput((prev) => ({ ...prev, [name]: value }));
  };

  const onItem = () => {
    // if (input.datetime === "") return;
    const newItem: CreateItemMutationVariables = {
      input: {
        name: input.name ? String(input.name) : "",
        price: input.price ? Number(input.price) : 0,
        ammount: input.ammount ? Number(input.ammount) : 0,
        unit: input.unit ? String(input.unit) : "",
        description: input.description ? String(input.description) : "",
        tax_rate: input.tax_rate ? Number(input.tax_rate) : 10,
        start_date: moment(input.start_date).format(),
        period: input.period ? String(input.period) : "",
        user_id: getUserId(),
        invoiceItemsId: getInvoiveId(),
        // invoice: getInvoiveId(),
      },
    };
    const now = moment(new Date()).format();
    setInput({
      id: "",
      name: "",
      price: 0,
      ammount: 0,
      unit: "",
      description: "",
      tax_rate: 10,
      start_date: moment(new Date()).format(),
      period: "",
      user_id: getUserId(),
      invoice: {
        id: getInvoiveId(),
        user_id: getUserId(),
        createdAt: "",
        updatedAt: "",
      },
    });
    API.graphql(graphqlOperation(createItem, newItem));
    // API.graphql({
    //   query: createItem,
    //   variables: newItem,
    //   authMode: "AMAZON_COGNITO_USER_POOLS",
    // });
    setModal(false);
  };

  const onEditCellChangeCommitted = (e: any) => {
    console.log(e.field);
    const id = e.id;
    const input: any = { id: id };
    input[e.field] = e.props.value;

    console.log(input);

    const updatedItem: UpdateItemMutationVariables = {
      input: input,
    };
    API.graphql(graphqlOperation(updateItem, updatedItem));
  };

  const Today = new Date();
  const [datetime, setDatetime] = React.useState(Today);
  registerLocale("ja", ja);

  const [modal, setModal] = useState<boolean>(false);
  //   const onClickDatetime = (e) => {
  //     console.log(e);
  //     setModal(true);
  //   };

  // const onChangeDateIssue = (selectedDate: Date) => {
  //   //   console.log(selectedDate);
  //   // selectedDate || Today
  //   setUser((prevState: Item) => {
  //     let jasper = Object.assign({}, prevState);
  //     jasper.issue_date = moment(selectedDate).format();
  //     return jasper;
  //   });
  //   const updatedUser: UpdateUserMutationVariables = {
  //     input: {
  //       id: getUserId(),
  //       issue_date: moment(selectedDate).format(),
  //     },
  //   };
  //   API.graphql(graphqlOperation(updateUser, updatedUser));
  // };
  // const onChangeDateDue = (selectedDate: Date) => {
  //   //   console.log(selectedDate);
  //   // selectedDate || Today
  //   setUser((prevState: Item) => {
  //     let jasper = Object.assign({}, prevState);
  //     jasper.due_date = moment(selectedDate).format();
  //     return jasper;
  //   });
  //   const updatedUser: UpdateUserMutationVariables = {
  //     input: {
  //       id: getUserId(),
  //       due_date: moment(selectedDate).format(),
  //     },
  //   };
  //   API.graphql(graphqlOperation(updateUser, updatedUser));
  // };
  const calcTotalAmount = (_items: any) => {
    // let items = Invoices.listInvoices.items as Invoice[]

    let total_ammount = _items.map((val: any) => {
      // console.log(val);
      let res =
        parseInt(val.price) *
        parseInt(val.ammount) *
        (1 + parseFloat(val.tax_rate) / 100);
      return Math.round(res * 1) / 1;
    });

    total_ammount = total_ammount.reduce(function (sum: number, element: any) {
      return sum + element;
    }, 0); // parseInt(_obj.items.price) * parseInt(_obj.items.ammount)
    return total_ammount;
  };

  const totalAmount = calcTotalAmount(items);

  const initModal = () => {
    // setDate(Today);
    // setEditMode(false);
    // setItems({
    //   id: "",
    //   type: "",
    //   key: "",
    //   value: 0,
    //   user_id: getUserId(),
    // });
    setModal(true);
  };

  const total_tax_8_base = items
    .map((val: any) => {
      // console.log(val);
      if (val.tax_rate !== 8) {
        return 0;
      }
      let res = parseInt(val.price) * parseInt(val.ammount);
      return Math.round(res);
    })
    .reduce((accumulator, currentValue) => {
      return accumulator + currentValue;
    }, 0);

  const total_tax_8_tax = items
    .map((val: any) => {
      // console.log(val);
      if (val.tax_rate !== 8) {
        return 0;
      }
      let res =
        parseInt(val.price) *
        parseInt(val.ammount) *
        (parseFloat(val.tax_rate) / 100);
      return Math.round(res);
    })
    .reduce((accumulator, currentValue) => {
      return accumulator + currentValue;
    }, 0);

  const total_tax_10_base = items
    .map((val: any) => {
      // console.log(val);
      if (val.tax_rate !== 10) {
        return 0;
      }
      let res = parseInt(val.price) * parseInt(val.ammount);
      return Math.round(res);
    })
    .reduce((accumulator, currentValue) => {
      return accumulator + currentValue;
    }, 0);

  const total_tax_10_tax = items
    .map((val: any) => {
      // console.log(val);
      if (val.tax_rate !== 10) {
        return 0;
      }
      let res =
        parseInt(val.price) *
        parseInt(val.ammount) *
        (parseFloat(val.tax_rate) / 100);
      return Math.round(res);
    })
    .reduce((accumulator, currentValue) => {
      return accumulator + currentValue;
    }, 0);

  // console.log(total_tax_10_base, total_tax_10_tax);

  const dom = (
    <GenericTemplate title="請求書">
      <ThemeProvider theme={theme}>
        <Box p={2} color="rgb(92, 44, 143);">
          <form action="/users" acceptCharset="UTF-8" method="post">
            <div>
              <TextField
                disabled={true}
                id="id"
                type="text"
                name="id"
                label="請求書番号"
                style={{ width: 500 }}
                value={invoice.id}
              />
            </div>

            <div>
              <TextField
                disabled={true}
                id="title"
                type="text"
                name="title"
                label="請求書タイトル"
                style={{ width: 500 }}
                value={invoice.title}
              />
            </div>

            <div>
              <TextField
                disabled={true}
                id="suppliers"
                type="text"
                name="suppliers"
                label="取引先"
                style={{ width: 500 }}
                value={invoice.suppliers}
              />
            </div>

            <div>
              <TextField
                disabled={true}
                id="note"
                type="text"
                name="note"
                label="取引先登録番号"
                style={{ width: 500 }}
                value={invoice.note}
              />
            </div>

            <div>
              <TextField
                disabled={true}
                id="suppliers_address"
                type="text"
                name="suppliers_address"
                label="取引先住所"
                style={{ width: 500 }}
                value={invoice.suppliers_address}
              />
            </div>

            {/* <div>
              <TextField
                id="bussiness_information"
                type="text"
                name="bussiness_information"
                label="事業情報（自社情報）"
                style={{ width: 500 }}
                value={invoice.bussiness_information}
              />
            </div>

            <div>
              <TextField
                id="status"
                type="text"
                name="status"
                label="ステータス"
                style={{ width: 500 }}
                value={invoice.status}
              />
            </div> */}

            {/* <div>
              <TextField
                id="charge_amount"
                type="text"
                name="charge_amount"
                label="請求金額"
                style={{ width: 500 }}
                value={invoice.charge_amount}
              />
            </div> */}

            <div>
              <label>発行日</label>
              <DatePicker
                disabled={true}
                className="customDatePicker"
                dateFormat="yyyy/MM/dd"
                locale="ja"
                selected={
                  invoice.issue_date
                    ? moment(invoice.issue_date).toDate()
                    : undefined
                }
                // minDate={Today}

                onChange={(selectedDate) => {
                  //   console.log(selectedDate);
                  setInput((prev) => ({
                    ...prev,
                    ["issue_date"]: moment(selectedDate).format(),
                  }));
                  // setDatetime(selectedDate || Today);
                }}
              />
            </div>

            <div>
              <label>支払期限</label>
              <DatePicker
                disabled={true}
                className="customDatePicker"
                dateFormat="yyyy/MM/dd"
                locale="ja"
                selected={
                  invoice.due_date
                    ? moment(invoice.due_date).toDate()
                    : undefined
                }
                // minDate={Today}

                onChange={(selectedDate) => {
                  //   console.log(selectedDate);
                  setInput((prev) => ({
                    ...prev,
                    ["due_date"]: moment(selectedDate).format(),
                  }));
                  // setDatetime(selectedDate || Today);
                }}
              />
            </div>

            {/* <div>
              <TextField
                id="send_offline"
                type="text"
                name="send_offline"
                label="送付"
                style={{ width: 500 }}
                value={invoice.send_offline}
              />
            </div>

            <div>
              <TextField
                id="send_online"
                type="text"
                name="send_online"
                label="送信"
                style={{ width: 500 }}
                value={invoice.send_online}
              />
            </div> */}

            {/* <div>
              <TextField
                id="person"
                type="text"
                name="person"
                label="担当者"
                style={{ width: 500 }}
                value={invoice.person}
              />
            </div> */}

            <div>
              <TextField
                disabled={true}
                id="assignee"
                type="text"
                name="assignee"
                label="担当者"
                style={{ width: 500 }}
                value={invoice.assignee}
              />
            </div>

            <div>
              <TextField
                disabled={true}
                id="account_information"
                type="text"
                name="account_information"
                label="口座情報"
                style={{ width: 500 }}
                value={invoice.account_information}
              />
            </div>
            <h4>請求金額</h4>
            <div>
              <TextField
                disabled={true}
                id="total_amount"
                type="text"
                name="total_amount"
                label=""
                style={{ width: 500 }}
                value={`${totalAmount}円`}
              />
            </div>
            <div>
              <h4>8%税率</h4>
              <div>
                <TextField
                  disabled={true}
                  id="8%"
                  type="text"
                  name="8%"
                  label=""
                  style={{ width: 500 }}
                  value={`8%対象${total_tax_8_base}円`}
                />
              </div>
              <div>
                <TextField
                  disabled={true}
                  id="8%"
                  type="text"
                  name="8%"
                  label=""
                  style={{ width: 500 }}
                  value={`8%消費税${total_tax_8_tax}円`}
                />
              </div>
              <h4>10%税率</h4>
              <div>
                <TextField
                  disabled={true}
                  id="10%"
                  type="text"
                  name="10%"
                  label=""
                  style={{ width: 500 }}
                  value={`10%対象${total_tax_10_base}円`}
                />
              </div>
              <div>
                <TextField
                  disabled={true}
                  id="10%"
                  type="text"
                  name="10%"
                  label=""
                  style={{ width: 500 }}
                  value={`10%消費税${total_tax_10_tax}円`}
                />
              </div>
            </div>
          </form>
        </Box>
      </ThemeProvider>

      <div style={{ height: 700, width: "100%" }}>
        <Grid
          container
          justify="flex-end"
          style={{ margin: "10px auto 10px auto" }}
        >
          <Button
            variant="contained"
            color="inherit"
            name="commit"
            onClick={initModal}
            style={{ marginRight: "10px" }} // Added margin to separate buttons
          >
            新規追加
          </Button>
          <Button
            variant="contained"
            color="inherit"
            name="commit"
            onClick={() => {
              // generatePDF();
              window.location.href = "/exported-invoice/" + invoice.id;
            }}
          >
            請求書発行
          </Button>
        </Grid>

        <Modal
          open={modal}
          onClose={() => {
            setModal(false);
          }}
          //   aria-labelledby="modal-modal-title"
          //   aria-describedby="modal-modal-description"
        >
          <Box p={2} bgcolor="white" color="rgb(92, 44, 143);">
            <Box p={2} bgcolor="rgb(92, 44, 143);" color="primary.contrastText">
              新規登録 　{" "}
            </Box>

            <form action="/users" acceptCharset="UTF-8" method="post">
              <div>
                <TextField
                  id="name"
                  type="text"
                  name="name"
                  label="商品名"
                  style={{ width: 500 }}
                  value={input.name}
                  onChange={onFormChange}
                />
              </div>

              <div>
                <TextField
                  id="price"
                  type="text"
                  name="price"
                  label="単価"
                  style={{ width: 500 }}
                  value={input.price}
                  onChange={onFormChange}
                />
              </div>

              <div>
                <TextField
                  id="ammount"
                  type="text"
                  name="ammount"
                  label="数量"
                  style={{ width: 500 }}
                  value={input.ammount}
                  onChange={onFormChange}
                />
              </div>

              <div>
                <TextField
                  id="tax_rate"
                  type="text"
                  name="tax_rate"
                  label="税率(%)"
                  style={{ width: 500 }}
                  value={input.tax_rate}
                  onChange={onFormChange}
                />
              </div>

              {/* <div>
              <TextField
                id="unit"
                type="text"
                name="unit"
                label="単位"
                style={{ width: 500 }}
                value={input.unit}
                onChange={onFormChange}
              />
            </div> */}

              <div>
                <label>開始日</label>
                <DatePicker
                  className="customDatePicker"
                  dateFormat="yyyy/MM/dd"
                  locale="ja"
                  selected={
                    input.start_date
                      ? moment(input.start_date).toDate()
                      : undefined
                  }
                  // minDate={Today}
                  // onChange={onFormChange}
                  onChange={(selectedDate) => {
                    //   console.log(selectedDate);
                    setInput((prev) => ({
                      ...prev,
                      ["start_date"]: moment(selectedDate).format(),
                    }));
                    // setDatetime(selectedDate || Today);
                  }}
                />
              </div>

              {/* <div>
                <TextField
                  id="period"
                  type="text"
                  name="period"
                  label="期間"
                  style={{ width: 500 }}
                  value={input.period}
                  onChange={onFormChange}
                />
              </div> */}

              {/* <div>
              <TextField
                id="person"
                type="text"
                name="person"
                label="担当者"
                style={{ width: 500 }}
                value={input.person}
                onChange={onFormChange}
              />
            </div> */}

              <Button
                style={{ margin: "10px auto 10px auto" }}
                variant="contained"
                color="inherit"
                name="commit"
                onClick={onItem}
              >
                出力
              </Button>
            </form>
          </Box>
        </Modal>

        <DataGrid
          rows={items}
          columns={columns}
          onCellClick={(e) => {
            console.log(e);
            // setModal(true);
          }}
          onEditCellChangeCommitted={onEditCellChangeCommitted}
          // checkboxSelection
          disableColumnMenu={false}
          sortModel={[
            {
              field: "start_date",
              sort: "asc",
            },
          ]}
        />
      </div>
      <div id="invoice-container"></div>
    </GenericTemplate>
  );

  // const App = () => <PDFViewer>{dom}</PDFViewer>;
  return dom;
  // return (
  //   <div>
  //     {dom}
  //     <PDFDownloadLink document={dom} fileName="somename.pdf">
  //       {({ blob, url, loading, error }) =>
  //         loading ? "Loading document..." : "Download now!"
  //       }
  //     </PDFDownloadLink>
  //   </div>
  // );
};

// const generatePDF = async () => {
//   try {
//     const element = document.getElementById("invoice-container");
//     const pdf = new jsPDF({
//       orientation: "portrait",
//       unit: "pt",
//       format: [element!.clientWidth, element!.clientHeight],
//     });
//     pdf.html(element!, {
//       callback: function (pdf) {
//         pdf.save("invoice.pdf");
//       },
//     });
//   } catch (error) {
//     console.error("Failed to generate PDF:", error);
//   }
// };

export default ProductPage;
