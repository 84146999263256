import GenericTemplate from "../templates/GenericTemplate";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import { ThemeProvider } from "@material-ui/styles";
import Box from "@material-ui/core/Box";
import { createMuiTheme } from "@material-ui/core/styles";

import moment from "moment";
import DatePicker, { registerLocale } from "react-datepicker";
import ja from "date-fns/locale/ja";

import React, { useEffect, useState } from "react";
import { API, graphqlOperation } from "aws-amplify";
import {
  listCashRegisters,
  listCashRegistersByUser,
} from "../../graphql/queries";
import {
  createCashRegister,
  updateCashRegister,
  deleteCashRegister,
  updateUser,
} from "../../graphql/mutations";
import {
  onCreateCashRegister,
  onDeleteCashRegister,
  onUpdateCashRegister,
} from "../../graphql/subscriptions";
import {
  ListCashRegistersQuery,
  ListCashRegistersByUserQuery,
  OnCreateCashRegisterSubscription,
  OnDeleteCashRegisterSubscription,
  CreateCashRegisterMutationVariables,
  UpdateCashRegisterMutationVariables,
  DeleteCashRegisterMutationVariables,
  OnUpdateCashRegisterSubscription,
  UpdateUserMutationVariables,
} from "../../API";

import { DataGrid } from "@material-ui/data-grid";

import { getUserId } from "../../util";

import { useUser, User } from "../../util/user";

const theme = createMuiTheme({
  palette: {
    primary: { main: "#00838f" },
    secondary: { main: "#e0f7fa" },
  },
});

const onClickDeleteCashRegister = (id: string) => {
  const deletedCashRegister: DeleteCashRegisterMutationVariables = {
    input: {
      id: id,
    },
  };
  API.graphql(graphqlOperation(deleteCashRegister, deletedCashRegister));
};

const columns = [
  // { field: 'id', headerName: 'id', width: 90, editable: false },
  {
    field: "datetime",
    headerName: "日時",
    width: 240,
    editable: true,
    renderCell: (params: any) => {
      return <div>{moment(params.value).format("YYYY/MM/DD")}</div>;
      //   return (
      //     <div>
      //       <DatePicker
      //         dateFormat="yyyy/MM/dd"
      //         locale="ja"
      //         selected={params.value}
      //         // minDate={Today}
      //         // onChange={onFormChange}
      //         onChange={(selectedDate) => {
      //           // setDate(selectedDate || Today);
      //         }}
      //       />
      //     </div>
      //   );
    },
  },
  { field: "account", headerName: "勘定科目", width: 180, editable: true },
  { field: "summary", headerName: "摘要", width: 180, editable: true },
  {
    field: "deposit_amount",
    headerName: "入金金額",
    width: 180,
    editable: true,
  },
  {
    field: "withdrawal_amount",
    headerName: "出金金額",
    width: 180,
    editable: true,
  },
  { field: "balance", headerName: "差引残高", width: 180, editable: false },
  // {
  //     field: 'createdAt',
  //     headerName: '追加日',
  //     type: 'date',
  //     width: 240,
  //     editable: false,
  // },
  {
    field: "",
    headerName: "",
    width: 85,
    disableClickEventBubbling: true,
    renderCell: (params: any) => {
      const onClick = () => {
        onClickDeleteCashRegister(params.id);
        return;
      };

      return (
        <Button
          variant="contained"
          color="secondary"
          name="commit"
          onClick={onClick}
        >
          削除
        </Button>
      );
    },
  },
];

type FormState = {
  id: string;
  datetime: string;
  summary?: string | null;
  account?: string | null;
  deposit_amount?: number | null;
  withdrawal_amount?: number | null;
  balance?: number | null;
  user_id: string;
};

type CashRegister = FormState & {
  createdAt: string;
  updatedAt: string;
};

type CashRegisterSubscriptionEventCreate = {
  value: { data: OnCreateCashRegisterSubscription };
};
type CashRegisterSubscriptionEventDelete = {
  value: { data: OnDeleteCashRegisterSubscription };
};
type CashRegisterSubscriptionEventUpdate = {
  value: { data: OnUpdateCashRegisterSubscription };
};

const useCashRegisters = () => {
  const [CashRegisters, setCashRegisters] = useState<CashRegister[]>([]);

  useEffect(() => {
    (async () => {
      let nextToken;
      let items: CashRegister[] = [];
      while (true) {
        const result = await API.graphql(
          graphqlOperation(listCashRegistersByUser, {
            user_id: getUserId(),
            nextToken: nextToken,
          })
        );
        if ("data" in result && result.data) {
          const newJournals = result.data as ListCashRegistersByUserQuery;

          if (newJournals.listCashRegistersByUser) {
            const tempItems = newJournals.listCashRegistersByUser
              .items as CashRegister[];
            items = items.concat(tempItems);
          }
          if (newJournals.listCashRegistersByUser?.nextToken) {
            nextToken = newJournals.listCashRegistersByUser?.nextToken;
          } else {
            setCashRegisters(items as CashRegister[]);
            break;
          }
        }
      }

      const clientCreateCashRegister = API.graphql(
        graphqlOperation(onCreateCashRegister, {
          owner: getUserId(),
        })
      );
      if ("subscribe" in clientCreateCashRegister) {
        clientCreateCashRegister.subscribe({
          next: ({ value: { data } }: CashRegisterSubscriptionEventCreate) => {
            if (data.onCreateCashRegister) {
              const cashRegister: CashRegister = data.onCreateCashRegister;
              if (
                cashRegister.withdrawal_amount &&
                cashRegister.deposit_amount
              ) {
                cashRegister.balance = getBalance(
                  cashRegister.withdrawal_amount,
                  cashRegister.deposit_amount
                );
              }

              setCashRegisters((prev) => [...prev, cashRegister]);
            }
          },
        });
      }

      const clientDeleteCashRegister = API.graphql(
        graphqlOperation(onDeleteCashRegister, {
          owner: getUserId(),
        })
      );
      if ("subscribe" in clientDeleteCashRegister) {
        clientDeleteCashRegister.subscribe({
          next: ({ value: { data } }: CashRegisterSubscriptionEventDelete) => {
            if (data.onDeleteCashRegister) {
              const cashRegister: CashRegister = data.onDeleteCashRegister;
              if (
                cashRegister.withdrawal_amount &&
                cashRegister.deposit_amount
              ) {
                cashRegister.balance = getBalance(
                  cashRegister.withdrawal_amount,
                  cashRegister.deposit_amount
                );
              }
              setCashRegisters((prev) => {
                return prev.filter((obj) => obj.id !== cashRegister.id);
              });
            }
          },
        });
      }

      const clientUpdateCashRegister = API.graphql(
        graphqlOperation(onUpdateCashRegister, {
          owner: getUserId(),
        })
      );
      if ("subscribe" in clientUpdateCashRegister) {
        clientUpdateCashRegister.subscribe({
          next: ({ value: { data } }: CashRegisterSubscriptionEventUpdate) => {
            if (data.onUpdateCashRegister) {
              const cashRegister: CashRegister = data.onUpdateCashRegister;
              if (
                cashRegister.withdrawal_amount &&
                cashRegister.deposit_amount
              ) {
                cashRegister.balance = getBalance(
                  cashRegister.withdrawal_amount,
                  cashRegister.deposit_amount
                );
              }
              setCashRegisters((prev) => [
                ...prev.filter((obj) => obj.id !== cashRegister.id),
                cashRegister,
              ]);
            }
          },
        });
      }
    })();
  }, []);

  return CashRegisters;
};

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
});

const getBalance = (withdrawal_amount: number, deposit_amount: number) => {
  let balance = 0;
  if (withdrawal_amount && deposit_amount) {
    balance = deposit_amount - withdrawal_amount;
  }
  // console.log(deposit_amount, withdrawal_amount)
  return balance;
};

const update = (cashRegisters: CashRegister[]) => {
  cashRegisters = cashRegisters.map((_obj) => {
    let obj = Object.assign({}, _obj);
    if (obj.withdrawal_amount && obj.deposit_amount) {
      obj.balance = getBalance(obj.withdrawal_amount, obj.deposit_amount);
      return obj;
    } else {
      obj.balance = 0;
      return obj;
    }
  });
  return cashRegisters;
};

const ProductPage: React.FC = () => {
  const [input, setInput] = useState<FormState>({
    id: "",
    datetime: "",
    summary: "",
    account: "",
    deposit_amount: 0,
    withdrawal_amount: 0,
    balance: getBalance(0, 0),
    user_id: getUserId(),
  });
  const cashRegisters = useCashRegisters();
  const [user, setUser] = useUser();

  const [filterNosummary, setFilterNosummary] = useState<boolean>();

  const onFormChange = ({
    target: { name, value },
  }: React.ChangeEvent<HTMLInputElement>) => {
    setInput((prev) => ({ ...prev, [name]: value }));
  };

  const onCashRegister = () => {
    // if (input.datetime === "") return;
    const newCashRegister: CreateCashRegisterMutationVariables = {
      input: {
        datetime: moment(datetime).format(),
        account: input.account ? String(input.account) : "",
        summary: input.summary ? String(input.summary) : "",
        deposit_amount: input.deposit_amount ? Number(input.deposit_amount) : 0,
        withdrawal_amount: input.withdrawal_amount
          ? Number(input.withdrawal_amount)
          : 0,
        balance: input.balance ? Number(input.balance) : 0,
        user_id: getUserId(),
      },
    };
    setInput({
      id: "",
      datetime: "",
      summary: "",
      account: "",
      deposit_amount: 0,
      withdrawal_amount: 0,
      balance: getBalance(0, 0),
      user_id: getUserId(),
    });
    API.graphql(graphqlOperation(createCashRegister, newCashRegister));
  };

  const onEditCellChangeCommitted = (e: any) => {
    console.log(e.field);
    const id = e.id;
    let input;
    if (e.field == "datetime") {
      input = {
        id: id,
        datetime: e.props.value,
      };
    } else if (e.field == "summary") {
      input = {
        id: id,
        summary: e.props.value ? String(e.props.value) : "",
      };
    } else if (e.field == "deposit_amount") {
      input = {
        id: id,
        deposit_amount: e.props.value ? Number(e.props.value) : 0,
      };
    } else if (e.field == "withdrawal_amount") {
      input = {
        id: id,
        withdrawal_amount: e.props.value ? Number(e.props.value) : 0,
      };
    } else if (e.field == "account") {
      input = {
        id: id,
        account: e.props.value ? String(e.props.value) : "",
      };
    } else {
      return;
    }
    console.log(input);

    const updatedCashRegister: UpdateCashRegisterMutationVariables = {
      input: input,
    };
    API.graphql(graphqlOperation(updateCashRegister, updatedCashRegister));
  };

  const Today = new Date();
  const [datetime, setDatetime] = React.useState(Today);
  registerLocale("ja", ja);

  const onChangeDate = (selectedDate: Date) => {
    //   console.log(selectedDate);
    // selectedDate || Today
    setUser((prevState: User) => {
      let jasper = Object.assign({}, prevState);
      jasper.datetime_cash_register = moment(selectedDate).format();
      return jasper;
    });
    const updatedUser: UpdateUserMutationVariables = {
      input: {
        id: getUserId(),
        datetime_cash_register: moment(selectedDate).format(),
      },
    };
    API.graphql(graphqlOperation(updateUser, updatedUser));
  };

  return (
    <GenericTemplate title="現金出納帳">
      <ThemeProvider theme={theme}>
        <Box>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div style={{ margin: "0 auto 10px 0" }}>
              <DatePicker
                //   className="customDatePicker"
                dateFormat="yyyy/MM/dd"
                locale="ja"
                selected={
                  user.datetime_cash_register
                    ? moment(user.datetime_cash_register).toDate()
                    : undefined
                }
                //   selected={new Date()}
                // minDate={Today}
                // onChange={onFormChange}
                onChange={onChangeDate}
              />
            </div>
            <div style={{ margin: "0 0 10px auto" }}>
              <TextField
                id="name"
                type="text"
                // label='表示名'
                style={{ width: 500 }}
                value={user.name}
              >
                {user.name}
              </TextField>
            </div>
          </div>
        </Box>

        <Box p={2} bgcolor="rgb(92, 44, 143);" color="primary.contrastText">
          新規登録 　{" "}
        </Box>

        <Box p={2} bgcolor="secondary.main" color="rgb(92, 44, 143);">
          <form action="/users" acceptCharset="UTF-8" method="post">
            <div>
              <DatePicker
                className="customDatePicker"
                dateFormat="yyyy/MM/dd"
                locale="ja"
                selected={datetime}
                // minDate={Today}
                // onChange={onFormChange}
                onChange={(selectedDate) => {
                  //   console.log(selectedDate);
                  setDatetime(selectedDate || Today);
                }}
              />
            </div>
            <div>
              <TextField
                id="account"
                type="text"
                name="account"
                label="勘定科目"
                style={{ width: 500 }}
                value={input.account}
                onChange={onFormChange}
              />
            </div>
            <div>
              <TextField
                id="summary"
                type="text"
                name="summary"
                label="摘要"
                style={{ width: 500 }}
                value={input.summary}
                onChange={onFormChange}
              />
            </div>
            <div>
              <TextField
                id="deposit_amount"
                type="text"
                name="deposit_amount"
                label="入金金額"
                style={{ width: 500 }}
                value={input.deposit_amount}
                onChange={onFormChange}
              />
            </div>
            <div>
              <TextField
                id="withdrawal_amount"
                type="text"
                name="withdrawal_amount"
                label="出金金額"
                style={{ width: 500 }}
                value={input.withdrawal_amount}
                onChange={onFormChange}
              />
            </div>
            {/* <div>
              <TextField
                id="balance"
                type="text"
                name="balance"
                label="差引残高"
                style={{ width: 500 }}
                value={input.balance}
                onChange={onFormChange}
              />
            </div> */}
            <Button
              style={{ margin: "10px auto 10px auto" }}
              variant="contained"
              color="inherit"
              name="commit"
              onClick={onCashRegister}
            >
              出力
            </Button>
          </form>
        </Box>
      </ThemeProvider>

      <div style={{ height: 700, width: "100%" }}>
        <DataGrid
          rows={cashRegisters}
          columns={columns}
          onEditCellChangeCommitted={onEditCellChangeCommitted}
          // checkboxSelection
          sortModel={[
            {
              field: "datetime",
              sort: "asc",
            },
          ]}
        />
      </div>
    </GenericTemplate>
  );
};

export default ProductPage;
