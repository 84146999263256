import GenericTemplate from "../templates/GenericTemplate";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import { ThemeProvider } from "@material-ui/styles";
import Box from "@material-ui/core/Box";
import { createMuiTheme } from "@material-ui/core/styles";

import moment from "moment";
import DatePicker, { registerLocale } from "react-datepicker";
import ja from "date-fns/locale/ja";

import React, { useEffect, useState } from "react";
import { API, graphqlOperation } from "aws-amplify";
import {
  listGeneralLedgers,
  listGeneralLedgersByUser,
} from "../../graphql/queries";
import {
  createGeneralLedger,
  updateGeneralLedger,
  deleteGeneralLedger,
  updateUser,
} from "../../graphql/mutations";
import {
  onCreateGeneralLedger,
  onDeleteGeneralLedger,
  onUpdateGeneralLedger,
} from "../../graphql/subscriptions";
import {
  ListGeneralLedgersQuery,
  ListGeneralLedgersByUserQuery,
  OnCreateGeneralLedgerSubscription,
  OnDeleteGeneralLedgerSubscription,
  CreateGeneralLedgerMutationVariables,
  UpdateGeneralLedgerMutationVariables,
  DeleteGeneralLedgerMutationVariables,
  OnUpdateGeneralLedgerSubscription,
  UpdateUserMutationVariables,
} from "../../API";

import { DataGrid } from "@material-ui/data-grid";

import { getUserId } from "../../util";

import { useUser, User } from "../../util/user";

const theme = createMuiTheme({
  palette: {
    primary: { main: "#00838f" },
    secondary: { main: "#e0f7fa" },
  },
});

const onClickDeleteGeneralLedger = (id: string) => {
  const deletedGeneralLedger: DeleteGeneralLedgerMutationVariables = {
    input: {
      id: id,
    },
  };
  API.graphql(graphqlOperation(deleteGeneralLedger, deletedGeneralLedger));
};

const columns = [
  // { field: 'id', headerName: 'id', width: 90, editable: false },
  {
    field: "datetime",
    headerName: "日時",
    width: 240,
    editable: true,
    renderCell: (params: any) => {
      return <div>{moment(params.value).format("YYYY/MM/DD")}</div>;
      //   return (
      //     <div>
      //       <DatePicker
      //         dateFormat="yyyy/MM/dd"
      //         locale="ja"
      //         selected={params.value}
      //         // minDate={Today}
      //         // onChange={onFormChange}
      //         onChange={(selectedDate) => {
      //           // setDate(selectedDate || Today);
      //         }}
      //       />
      //     </div>
      //   );
    },
  },
  { field: "summary", headerName: "摘要", width: 180, editable: true },
  { field: "account", headerName: "勘定科目", width: 180, editable: true },
  { field: "journal", headerName: "仕丁", width: 180, editable: true },
  { field: "debit", headerName: "借方", width: 180, editable: true },
  { field: "credit", headerName: "貸方", width: 180, editable: true },
  { field: "balance", headerName: "残高", width: 180, editable: true },
  // {
  //     field: 'createdAt',
  //     headerName: '追加日',
  //     type: 'date',
  //     width: 240,
  //     editable: false,
  // },
  {
    field: "",
    headerName: "",
    width: 85,
    disableClickEventBubbling: true,
    renderCell: (params: any) => {
      const onClick = () => {
        onClickDeleteGeneralLedger(params.id);
        return;
      };

      return (
        <Button
          variant="contained"
          color="secondary"
          name="commit"
          onClick={onClick}
        >
          削除
        </Button>
      );
    },
  },
];

type FormState = {
  id: string;
  datetime: string;
  summary?: string | null;
  account?: string | null;
  journal?: string | null;
  debit?: number | null;
  credit?: number | null;
  balance?: number | null;
  user_id: string;
};

type GeneralLedger = FormState & {
  createdAt: string;
  updatedAt: string;
};

type GeneralLedgerSubscriptionEventCreate = {
  value: { data: OnCreateGeneralLedgerSubscription };
};
type GeneralLedgerSubscriptionEventDelete = {
  value: { data: OnDeleteGeneralLedgerSubscription };
};
type GeneralLedgerSubscriptionEventUpdate = {
  value: { data: OnUpdateGeneralLedgerSubscription };
};

const useGeneralLedgers = () => {
  const [GeneralLedgers, setGeneralLedgers] = useState<GeneralLedger[]>([]);

  useEffect(() => {
    (async () => {
      let nextToken;
      let items: GeneralLedger[] = [];
      while (true) {
        const result = await API.graphql(
          graphqlOperation(listGeneralLedgersByUser, {
            user_id: getUserId(),
            nextToken: nextToken,
          })
        );
        if ("data" in result && result.data) {
          const newJournals = result.data as ListGeneralLedgersByUserQuery;

          if (newJournals.listGeneralLedgersByUser) {
            const tempItems = newJournals.listGeneralLedgersByUser
              .items as GeneralLedger[];
            items = items.concat(tempItems);
          }
          if (newJournals.listGeneralLedgersByUser?.nextToken) {
            nextToken = newJournals.listGeneralLedgersByUser?.nextToken;
          } else {
            setGeneralLedgers(items as GeneralLedger[]);
            break;
          }
        }
      }

      const clientCreateGeneralLedger = API.graphql(
        graphqlOperation(onCreateGeneralLedger, {
          owner: getUserId(),
        })
      );
      if ("subscribe" in clientCreateGeneralLedger) {
        clientCreateGeneralLedger.subscribe({
          next: ({ value: { data } }: GeneralLedgerSubscriptionEventCreate) => {
            if (data.onCreateGeneralLedger) {
              const generalLedger: GeneralLedger = data.onCreateGeneralLedger;
              setGeneralLedgers((prev) => [...prev, generalLedger]);
            }
          },
        });
      }

      const clientDeleteGeneralLedger = API.graphql(
        graphqlOperation(onDeleteGeneralLedger, {
          owner: getUserId(),
        })
      );
      if ("subscribe" in clientDeleteGeneralLedger) {
        clientDeleteGeneralLedger.subscribe({
          next: ({ value: { data } }: GeneralLedgerSubscriptionEventDelete) => {
            if (data.onDeleteGeneralLedger) {
              const generalLedger: GeneralLedger = data.onDeleteGeneralLedger;
              setGeneralLedgers((prev) => {
                return prev.filter((obj) => obj.id !== generalLedger.id);
              });
            }
          },
        });
      }

      const clientUpdateGeneralLedger = API.graphql(
        graphqlOperation(onUpdateGeneralLedger, {
          owner: getUserId(),
        })
      );
      if ("subscribe" in clientUpdateGeneralLedger) {
        clientUpdateGeneralLedger.subscribe({
          next: ({ value: { data } }: GeneralLedgerSubscriptionEventUpdate) => {
            if (data.onUpdateGeneralLedger) {
              const generalLedger: GeneralLedger = data.onUpdateGeneralLedger;
              setGeneralLedgers((prev) => [
                ...prev.filter((obj) => obj.id !== generalLedger.id),
                generalLedger,
              ]);
            }
          },
        });
      }
    })();
  }, []);

  return GeneralLedgers;
};

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
});

const ProductPage: React.FC = () => {
  const [input, setInput] = useState<FormState>({
    id: "",
    datetime: "",
    summary: "",
    account: "",
    journal: "",
    debit: 0,
    credit: 0,
    balance: 0,
    user_id: getUserId(),
  });
  const generalLedgers = useGeneralLedgers();
  const [user, setUser] = useUser();

  const [filterNosummary, setFilterNosummary] = useState<boolean>();

  const onFormChange = ({
    target: { name, value },
  }: React.ChangeEvent<HTMLInputElement>) => {
    setInput((prev) => ({ ...prev, [name]: value }));
  };

  const onGeneralLedger = () => {
    // if (input.datetime === '') return;
    const newGeneralLedger: CreateGeneralLedgerMutationVariables = {
      input: {
        datetime: moment(datetime).format(),
        summary: input.summary ? String(input.summary) : "",
        account: input.account ? String(input.account) : "",
        journal: input.journal ? String(input.journal) : "",
        debit: input.debit ? Number(input.debit) : 0,
        credit: input.credit ? Number(input.credit) : 0,
        balance: input.balance ? Number(input.balance) : 0,
        user_id: getUserId(),
      },
    };
    setInput({
      id: "",
      datetime: "",
      summary: "",
      account: "",
      journal: "",
      debit: 0,
      credit: 0,
      balance: 0,
      user_id: getUserId(),
    });
    API.graphql(graphqlOperation(createGeneralLedger, newGeneralLedger));
  };

  const onEditCellChangeCommitted = (e: any) => {
    console.log(e.field);
    const id = e.id;
    let input;
    if (e.field == "datetime") {
      input = {
        id: id,
        datetime: e.props.value,
      };
    } else if (e.field == "summary") {
      input = {
        id: id,
        summary: e.props.value ? String(e.props.value) : "",
      };
    } else if (e.field == "account") {
      input = {
        id: id,
        account: e.props.value ? String(e.props.value) : "",
      };
    } else if (e.field == "journal") {
      input = {
        id: id,
        journal: e.props.value ? String(e.props.value) : "",
      };
    } else if (e.field == "debit") {
      input = {
        id: id,
        debit: e.props.value ? Number(e.props.value) : 0,
      };
    } else if (e.field == "credit") {
      input = {
        id: id,
        credit: e.props.value ? Number(e.props.value) : 0,
      };
    } else if (e.field == "balance") {
      input = {
        id: id,
        balance: e.props.value ? Number(e.props.value) : 0,
      };
    } else {
      return;
    }
    console.log(input);

    const updatedGeneralLedger: UpdateGeneralLedgerMutationVariables = {
      input: input,
    };
    API.graphql(graphqlOperation(updateGeneralLedger, updatedGeneralLedger));
  };

  const Today = new Date();
  const [datetime, setDatetime] = React.useState(Today);
  registerLocale("ja", ja);

  const onChangeDate = (selectedDate: Date) => {
    //   console.log(selectedDate);
    // selectedDate || Today
    setUser((prevState: User) => {
      let jasper = Object.assign({}, prevState);
      jasper.datetime_general_ledger = moment(selectedDate).format();
      return jasper;
    });
    const updatedUser: UpdateUserMutationVariables = {
      input: {
        id: getUserId(),
        datetime_general_ledger: moment(selectedDate).format(),
      },
    };
    API.graphql(graphqlOperation(updateUser, updatedUser));
  };

  return (
    <GenericTemplate title="総勘定元帳">
      <ThemeProvider theme={theme}>
        <Box>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div style={{ margin: "0 auto 10px 0" }}>
              <DatePicker
                //   className="customDatePicker"
                dateFormat="yyyy/MM/dd"
                locale="ja"
                selected={
                  user.datetime_general_ledger
                    ? moment(user.datetime_general_ledger).toDate()
                    : undefined
                }
                //   selected={new Date()}
                // minDate={Today}
                // onChange={onFormChange}
                onChange={onChangeDate}
              />
            </div>
            <div style={{ margin: "0 0 10px auto" }}>
              <TextField
                id="name"
                type="text"
                // label='表示名'
                style={{ width: 500 }}
                value={user.name}
              >
                {user.name}
              </TextField>
            </div>
          </div>
        </Box>

        <Box p={2} bgcolor="rgb(92, 44, 143);" color="primary.contrastText">
          新規登録 　{" "}
        </Box>

        <Box p={2} bgcolor="secondary.main" color="rgb(92, 44, 143);">
          <form action="/users" acceptCharset="UTF-8" method="post">
            <div>
              <DatePicker
                className="customDatePicker"
                dateFormat="yyyy/MM/dd"
                locale="ja"
                selected={datetime}
                // minDate={Today}
                // onChange={onFormChange}
                onChange={(selectedDate) => {
                  //   console.log(selectedDate);
                  setDatetime(selectedDate || Today);
                }}
              />
            </div>
            <div>
              <TextField
                id="summary"
                type="text"
                name="summary"
                label="摘要"
                style={{ width: 500 }}
                value={input.summary}
                onChange={onFormChange}
              />
            </div>
            <div>
              <TextField
                id="account"
                type="text"
                name="account"
                label="勘定科目"
                style={{ width: 500 }}
                value={input.account}
                onChange={onFormChange}
              />
            </div>
            <div>
              <TextField
                id="journal"
                type="text"
                name="journal"
                label="仕丁"
                style={{ width: 500 }}
                value={input.journal}
                onChange={onFormChange}
              />
            </div>
            <div>
              <TextField
                id="debit"
                type="text"
                name="debit"
                label="借方"
                style={{ width: 500 }}
                value={input.debit}
                onChange={onFormChange}
              />
            </div>
            <div>
              <TextField
                id="credit"
                type="text"
                name="credit"
                label="貸方"
                style={{ width: 500 }}
                value={input.credit}
                onChange={onFormChange}
              />
            </div>
            <div>
              <TextField
                id="balance"
                type="text"
                name="balance"
                label="残高"
                style={{ width: 500 }}
                value={input.balance}
                onChange={onFormChange}
              />
            </div>
            <Button
              style={{ margin: "10px auto 10px auto" }}
              variant="contained"
              color="inherit"
              name="commit"
              onClick={onGeneralLedger}
            >
              出力
            </Button>
          </form>
        </Box>
      </ThemeProvider>

      <div style={{ height: 700, width: "100%" }}>
        <DataGrid
          rows={generalLedgers}
          columns={columns}
          onEditCellChangeCommitted={onEditCellChangeCommitted}
          // checkboxSelection
          sortModel={[
            {
              field: "datetime",
              sort: "asc",
            },
          ]}
        />
      </div>
    </GenericTemplate>
  );
};

export default ProductPage;
