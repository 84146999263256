/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

import * as APITypes from "../API";
type GeneratedMutation<InputType, OutputType> = string & {
  __generatedMutationInput: InputType;
  __generatedMutationOutput: OutputType;
};

export const createUser = /* GraphQL */ `mutation CreateUser(
  $input: CreateUserInput!
  $condition: ModelUserConditionInput
) {
  createUser(input: $input, condition: $condition) {
    id
    name
    datetime_journal
    datetime_cash_register
    datetime_general_ledger
    datetime_trial_balance
    datetime_balance_sheet
    datetime_since_profit_and_loss_statement
    datetime_to_profit_and_loss_statement
    datetime_since_cash_flow_statement
    datetime_to_cash_flow_statement
    start_balance_cash_flow_statement
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateUserMutationVariables,
  APITypes.CreateUserMutation
>;
export const updateUser = /* GraphQL */ `mutation UpdateUser(
  $input: UpdateUserInput!
  $condition: ModelUserConditionInput
) {
  updateUser(input: $input, condition: $condition) {
    id
    name
    datetime_journal
    datetime_cash_register
    datetime_general_ledger
    datetime_trial_balance
    datetime_balance_sheet
    datetime_since_profit_and_loss_statement
    datetime_to_profit_and_loss_statement
    datetime_since_cash_flow_statement
    datetime_to_cash_flow_statement
    start_balance_cash_flow_statement
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateUserMutationVariables,
  APITypes.UpdateUserMutation
>;
export const deleteUser = /* GraphQL */ `mutation DeleteUser(
  $input: DeleteUserInput!
  $condition: ModelUserConditionInput
) {
  deleteUser(input: $input, condition: $condition) {
    id
    name
    datetime_journal
    datetime_cash_register
    datetime_general_ledger
    datetime_trial_balance
    datetime_balance_sheet
    datetime_since_profit_and_loss_statement
    datetime_to_profit_and_loss_statement
    datetime_since_cash_flow_statement
    datetime_to_cash_flow_statement
    start_balance_cash_flow_statement
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteUserMutationVariables,
  APITypes.DeleteUserMutation
>;
export const createJournal = /* GraphQL */ `mutation CreateJournal(
  $input: CreateJournalInput!
  $condition: ModelJournalConditionInput
) {
  createJournal(input: $input, condition: $condition) {
    id
    datetime
    summary
    ledger
    debit
    credit
    user_id
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateJournalMutationVariables,
  APITypes.CreateJournalMutation
>;
export const updateJournal = /* GraphQL */ `mutation UpdateJournal(
  $input: UpdateJournalInput!
  $condition: ModelJournalConditionInput
) {
  updateJournal(input: $input, condition: $condition) {
    id
    datetime
    summary
    ledger
    debit
    credit
    user_id
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateJournalMutationVariables,
  APITypes.UpdateJournalMutation
>;
export const deleteJournal = /* GraphQL */ `mutation DeleteJournal(
  $input: DeleteJournalInput!
  $condition: ModelJournalConditionInput
) {
  deleteJournal(input: $input, condition: $condition) {
    id
    datetime
    summary
    ledger
    debit
    credit
    user_id
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteJournalMutationVariables,
  APITypes.DeleteJournalMutation
>;
export const createCashRegister = /* GraphQL */ `mutation CreateCashRegister(
  $input: CreateCashRegisterInput!
  $condition: ModelCashRegisterConditionInput
) {
  createCashRegister(input: $input, condition: $condition) {
    id
    datetime
    account
    summary
    deposit_amount
    withdrawal_amount
    balance
    user_id
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateCashRegisterMutationVariables,
  APITypes.CreateCashRegisterMutation
>;
export const updateCashRegister = /* GraphQL */ `mutation UpdateCashRegister(
  $input: UpdateCashRegisterInput!
  $condition: ModelCashRegisterConditionInput
) {
  updateCashRegister(input: $input, condition: $condition) {
    id
    datetime
    account
    summary
    deposit_amount
    withdrawal_amount
    balance
    user_id
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateCashRegisterMutationVariables,
  APITypes.UpdateCashRegisterMutation
>;
export const deleteCashRegister = /* GraphQL */ `mutation DeleteCashRegister(
  $input: DeleteCashRegisterInput!
  $condition: ModelCashRegisterConditionInput
) {
  deleteCashRegister(input: $input, condition: $condition) {
    id
    datetime
    account
    summary
    deposit_amount
    withdrawal_amount
    balance
    user_id
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteCashRegisterMutationVariables,
  APITypes.DeleteCashRegisterMutation
>;
export const createGeneralLedger = /* GraphQL */ `mutation CreateGeneralLedger(
  $input: CreateGeneralLedgerInput!
  $condition: ModelGeneralLedgerConditionInput
) {
  createGeneralLedger(input: $input, condition: $condition) {
    id
    datetime
    summary
    account
    journal
    debit
    credit
    balance
    user_id
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateGeneralLedgerMutationVariables,
  APITypes.CreateGeneralLedgerMutation
>;
export const updateGeneralLedger = /* GraphQL */ `mutation UpdateGeneralLedger(
  $input: UpdateGeneralLedgerInput!
  $condition: ModelGeneralLedgerConditionInput
) {
  updateGeneralLedger(input: $input, condition: $condition) {
    id
    datetime
    summary
    account
    journal
    debit
    credit
    balance
    user_id
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateGeneralLedgerMutationVariables,
  APITypes.UpdateGeneralLedgerMutation
>;
export const deleteGeneralLedger = /* GraphQL */ `mutation DeleteGeneralLedger(
  $input: DeleteGeneralLedgerInput!
  $condition: ModelGeneralLedgerConditionInput
) {
  deleteGeneralLedger(input: $input, condition: $condition) {
    id
    datetime
    summary
    account
    journal
    debit
    credit
    balance
    user_id
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteGeneralLedgerMutationVariables,
  APITypes.DeleteGeneralLedgerMutation
>;
export const createTrialBalance = /* GraphQL */ `mutation CreateTrialBalance(
  $input: CreateTrialBalanceInput!
  $condition: ModelTrialBalanceConditionInput
) {
  createTrialBalance(input: $input, condition: $condition) {
    id
    debit
    account
    credit
    user_id
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateTrialBalanceMutationVariables,
  APITypes.CreateTrialBalanceMutation
>;
export const updateTrialBalance = /* GraphQL */ `mutation UpdateTrialBalance(
  $input: UpdateTrialBalanceInput!
  $condition: ModelTrialBalanceConditionInput
) {
  updateTrialBalance(input: $input, condition: $condition) {
    id
    debit
    account
    credit
    user_id
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateTrialBalanceMutationVariables,
  APITypes.UpdateTrialBalanceMutation
>;
export const deleteTrialBalance = /* GraphQL */ `mutation DeleteTrialBalance(
  $input: DeleteTrialBalanceInput!
  $condition: ModelTrialBalanceConditionInput
) {
  deleteTrialBalance(input: $input, condition: $condition) {
    id
    debit
    account
    credit
    user_id
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteTrialBalanceMutationVariables,
  APITypes.DeleteTrialBalanceMutation
>;
export const createBalanceSheet = /* GraphQL */ `mutation CreateBalanceSheet(
  $input: CreateBalanceSheetInput!
  $condition: ModelBalanceSheetConditionInput
) {
  createBalanceSheet(input: $input, condition: $condition) {
    id
    type
    key
    value
    user_id
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateBalanceSheetMutationVariables,
  APITypes.CreateBalanceSheetMutation
>;
export const updateBalanceSheet = /* GraphQL */ `mutation UpdateBalanceSheet(
  $input: UpdateBalanceSheetInput!
  $condition: ModelBalanceSheetConditionInput
) {
  updateBalanceSheet(input: $input, condition: $condition) {
    id
    type
    key
    value
    user_id
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateBalanceSheetMutationVariables,
  APITypes.UpdateBalanceSheetMutation
>;
export const deleteBalanceSheet = /* GraphQL */ `mutation DeleteBalanceSheet(
  $input: DeleteBalanceSheetInput!
  $condition: ModelBalanceSheetConditionInput
) {
  deleteBalanceSheet(input: $input, condition: $condition) {
    id
    type
    key
    value
    user_id
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteBalanceSheetMutationVariables,
  APITypes.DeleteBalanceSheetMutation
>;
export const createProfitAndLossStatement = /* GraphQL */ `mutation CreateProfitAndLossStatement(
  $input: CreateProfitAndLossStatementInput!
  $condition: ModelProfitAndLossStatementConditionInput
) {
  createProfitAndLossStatement(input: $input, condition: $condition) {
    id
    account
    debit
    credit
    user_id
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateProfitAndLossStatementMutationVariables,
  APITypes.CreateProfitAndLossStatementMutation
>;
export const updateProfitAndLossStatement = /* GraphQL */ `mutation UpdateProfitAndLossStatement(
  $input: UpdateProfitAndLossStatementInput!
  $condition: ModelProfitAndLossStatementConditionInput
) {
  updateProfitAndLossStatement(input: $input, condition: $condition) {
    id
    account
    debit
    credit
    user_id
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateProfitAndLossStatementMutationVariables,
  APITypes.UpdateProfitAndLossStatementMutation
>;
export const deleteProfitAndLossStatement = /* GraphQL */ `mutation DeleteProfitAndLossStatement(
  $input: DeleteProfitAndLossStatementInput!
  $condition: ModelProfitAndLossStatementConditionInput
) {
  deleteProfitAndLossStatement(input: $input, condition: $condition) {
    id
    account
    debit
    credit
    user_id
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteProfitAndLossStatementMutationVariables,
  APITypes.DeleteProfitAndLossStatementMutation
>;
export const createCashFlowStatement = /* GraphQL */ `mutation CreateCashFlowStatement(
  $input: CreateCashFlowStatementInput!
  $condition: ModelCashFlowStatementConditionInput
) {
  createCashFlowStatement(input: $input, condition: $condition) {
    id
    type
    key
    value
    user_id
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateCashFlowStatementMutationVariables,
  APITypes.CreateCashFlowStatementMutation
>;
export const updateCashFlowStatement = /* GraphQL */ `mutation UpdateCashFlowStatement(
  $input: UpdateCashFlowStatementInput!
  $condition: ModelCashFlowStatementConditionInput
) {
  updateCashFlowStatement(input: $input, condition: $condition) {
    id
    type
    key
    value
    user_id
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateCashFlowStatementMutationVariables,
  APITypes.UpdateCashFlowStatementMutation
>;
export const deleteCashFlowStatement = /* GraphQL */ `mutation DeleteCashFlowStatement(
  $input: DeleteCashFlowStatementInput!
  $condition: ModelCashFlowStatementConditionInput
) {
  deleteCashFlowStatement(input: $input, condition: $condition) {
    id
    type
    key
    value
    user_id
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteCashFlowStatementMutationVariables,
  APITypes.DeleteCashFlowStatementMutation
>;
export const createInvoice = /* GraphQL */ `mutation CreateInvoice(
  $input: CreateInvoiceInput!
  $condition: ModelInvoiceConditionInput
) {
  createInvoice(input: $input, condition: $condition) {
    id
    title
    suppliers
    suppliers_address
    bussiness_information
    status
    charge_amount
    issue_date
    due_date
    send_offline
    send_online
    person
    assignee
    note
    account_information
    user_id
    items {
      nextToken
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateInvoiceMutationVariables,
  APITypes.CreateInvoiceMutation
>;
export const updateInvoice = /* GraphQL */ `mutation UpdateInvoice(
  $input: UpdateInvoiceInput!
  $condition: ModelInvoiceConditionInput
) {
  updateInvoice(input: $input, condition: $condition) {
    id
    title
    suppliers
    suppliers_address
    bussiness_information
    status
    charge_amount
    issue_date
    due_date
    send_offline
    send_online
    person
    assignee
    note
    account_information
    user_id
    items {
      nextToken
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateInvoiceMutationVariables,
  APITypes.UpdateInvoiceMutation
>;
export const deleteInvoice = /* GraphQL */ `mutation DeleteInvoice(
  $input: DeleteInvoiceInput!
  $condition: ModelInvoiceConditionInput
) {
  deleteInvoice(input: $input, condition: $condition) {
    id
    title
    suppliers
    suppliers_address
    bussiness_information
    status
    charge_amount
    issue_date
    due_date
    send_offline
    send_online
    person
    assignee
    note
    account_information
    user_id
    items {
      nextToken
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteInvoiceMutationVariables,
  APITypes.DeleteInvoiceMutation
>;
export const createItem = /* GraphQL */ `mutation CreateItem(
  $input: CreateItemInput!
  $condition: ModelItemConditionInput
) {
  createItem(input: $input, condition: $condition) {
    id
    name
    item_code
    price
    ammount
    unit
    description
    tax_rate
    start_date
    period
    user_id
    invoice {
      id
      title
      suppliers
      suppliers_address
      bussiness_information
      status
      charge_amount
      issue_date
      due_date
      send_offline
      send_online
      person
      assignee
      note
      account_information
      user_id
      createdAt
      updatedAt
      __typename
    }
    createdAt
    updatedAt
    invoiceItemsId
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateItemMutationVariables,
  APITypes.CreateItemMutation
>;
export const updateItem = /* GraphQL */ `mutation UpdateItem(
  $input: UpdateItemInput!
  $condition: ModelItemConditionInput
) {
  updateItem(input: $input, condition: $condition) {
    id
    name
    item_code
    price
    ammount
    unit
    description
    tax_rate
    start_date
    period
    user_id
    invoice {
      id
      title
      suppliers
      suppliers_address
      bussiness_information
      status
      charge_amount
      issue_date
      due_date
      send_offline
      send_online
      person
      assignee
      note
      account_information
      user_id
      createdAt
      updatedAt
      __typename
    }
    createdAt
    updatedAt
    invoiceItemsId
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateItemMutationVariables,
  APITypes.UpdateItemMutation
>;
export const deleteItem = /* GraphQL */ `mutation DeleteItem(
  $input: DeleteItemInput!
  $condition: ModelItemConditionInput
) {
  deleteItem(input: $input, condition: $condition) {
    id
    name
    item_code
    price
    ammount
    unit
    description
    tax_rate
    start_date
    period
    user_id
    invoice {
      id
      title
      suppliers
      suppliers_address
      bussiness_information
      status
      charge_amount
      issue_date
      due_date
      send_offline
      send_online
      person
      assignee
      note
      account_information
      user_id
      createdAt
      updatedAt
      __typename
    }
    createdAt
    updatedAt
    invoiceItemsId
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteItemMutationVariables,
  APITypes.DeleteItemMutation
>;
