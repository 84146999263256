import React, { Component } from 'react'
import styles from './Invoice.module.scss'

import LineItems from './LineItems'

import { v4 as uuidv4 } from "uuid";

class Invoice extends Component {

  locale = 'ja-JP'
  currency = 'JPY'

  state = {
    fromCompany: '株式会社BBB',
    toCompany: '株式会社AAA',
    address: '東京都渋谷区渋谷２丁目XX-XX',
    invoiceNumber: '111122',
    invoiceRegisteredNumber: '74297390270238',
    taxRate: 10,
    discountedTaxRate: 8,
    dealDate: "2024-02-15",
    lineItems: [
      {
        id: 'initial',      // react-beautiful-dnd の一意のキー
        name: 'A',
        description: '',
        quantity: 1,
        price: 100,
        isDiscountedTax: 'なし',
      },
      {
        id: 'initial',      // react-beautiful-dnd の一意のキー
        name: 'B',
        description: '',
        quantity: 1,
        price: 100,
        isDiscountedTax: 'あり',
      },
    ]
  }

  handleInvoiceChange = (event) => {
    this.setState({[event.target.name]: event.target.value})
  }

  handleLineItemChange = (elementIndex) => (event) => {
    // console.log(event.target.value)
    let lineItems = this.state.lineItems.map((item, i) => {
      if (elementIndex !== i) return item
      return {...item, [event.target.name]: event.target.value}
    })
    this.setState({lineItems})
  }

  handleAddLineItem = (event) => {
    this.setState({
      // ドラッグアンドドロップのための楽観的な uuid を使用; 本番アプリではデータベース ID が使用される可能性があります
      lineItems: this.state.lineItems.concat(
        [{ id: uuidv4(), name: '', description: '', quantity: 0, price: 0.00 }]
      )
    })
  }

  handleRemoveLineItem = (elementIndex) => (event) => {
    this.setState({
      lineItems: this.state.lineItems.filter((item, i) => {
        return elementIndex !== i
      })
    })
  }

  handleReorderLineItems = (newLineItems) => {
    this.setState({
      lineItems: newLineItems,
    })
  }

  handleFocusSelect = (event) => {
    event.target.select()
  }

  handlePayButtonClick = () => {
    // 実装されていません
    window.print()
  }
  formatCurrency = (amount) => {
    return (new Intl.NumberFormat(this.locale, {
      style: 'currency',
      currency: this.currency,
      minimumFractionDigits: 0,
      maximumFractionDigits: 0
    }).format(amount))
  }

  calcTaxAmount = (c) => {
    return c * (this.state.taxRate / 100)
  }

  getTaxRate = (item) => {
    return item.isDiscountedTax === 'あり' ? this.state.discountedTaxRate / 100 : this.state.taxRate / 100
  }

  calcLineItemsTotal = () => {
    // return this.state.lineItems.reduce((prev, cur) => (prev + (cur.quantity * cur.price * (1.0 + this.getTaxRate(cur)))), 0)
    return this.state.lineItems.reduce((prev, cur) => (prev + (cur.quantity * cur.price)), 0)
  }

  calcTaxTotal = () => {
    // return this.calcLineItemsTotal() * (this.state.taxRate / 100)
    return this.calcGrandTotal() - this.state.lineItems.reduce((prev, cur) => (prev + (cur.quantity * cur.price)), 0)
  }

  calcGrandTotal = () => {
    // return this.calcLineItemsTotal() + this.calcTaxTotal()
    return this.state.lineItems.reduce((prev, cur) => (prev + (cur.quantity * cur.price * (1.0 + this.getTaxRate(cur)))), 0)
  }

  componentDidMount() {
    // console.log(this.props.items);

    const getInvoiveId = () => {
      const url = new URL(window.location.href);
      const id = url.pathname.split("/")[2];
      return id;
      // return  {
      //   id: id,
      //   user_id: getUserId(),
      //   createdAt: "",
      //   updatedAt: "",
      // }
    };

    const invoiceId = getInvoiveId()

    // console.log(invoiceId)
    
  }

  render = () => {
    return (

      <div className={styles.invoice}>
        <h1 className={styles.title}>請求明細書</h1>
        <div className={styles.brand}>
          {/* <img src="https://via.placeholder.com/150x50.png?text=logo" alt="ロゴ" className={styles.logo} /> */}
          <h2><input className={styles.input} name="toCompany" type="string" value={this.state.toCompany} onChange={this.handleInvoiceChange} onFocus={this.handleFocusSelect} /></h2>
        </div>
        <div className={styles.addresses}>
          <div className={styles.from}>
            <strong>住所</strong><br />
            <div className={styles.value}><textarea className={styles.address} name="address" type="string" value={this.state.address} onChange={this.handleInvoiceChange} onFocus={this.handleFocusSelect} /></div>
          </div>
          <div>
            <div className={`${styles.valueTable} ${styles.to} ${styles.valueTableAbove}`}>
              <div className={styles.row}>
                <div className={styles.label}>請求書番号</div>
                <div className={styles.value}><input className={styles.input} name="invoiceNumber" type="string" value={this.state.invoiceNumber} onChange={this.handleInvoiceChange} onFocus={this.handleFocusSelect} /></div>
              </div>
              <div className={styles.row}>
                <div className={styles.label}>インボイス<br />登録番号</div>
                <div className={styles.value}><input className={styles.input} name="invoiceRegisteredNumber" type="string" value={this.state.invoiceRegisteredNumber} onChange={this.handleInvoiceChange} onFocus={this.handleFocusSelect} /></div>
              </div>
              <div className={styles.row}>
                <div className={styles.label}>日付</div>
                <div className={styles.value}><input className={styles.input} name="dealDate" type="string" value={this.state.dealDate} onChange={this.handleInvoiceChange} onFocus={this.handleFocusSelect} /></div>
              </div>
            </div>
          </div>
        </div>
        <h2>明細</h2>

          <LineItems
            items={this.state.lineItems}
            currencyFormatter={this.formatCurrency}
            addHandler={this.handleAddLineItem}
            changeHandler={this.handleLineItemChange}
            focusHandler={this.handleFocusSelect}
            deleteHandler={this.handleRemoveLineItem}
            reorderHandler={this.handleReorderLineItems}
            taxRate={this.state.taxRate}
            discountedTaxRate={this.state.discountedTaxRate}
          />

        <div className={styles.totalContainer}>
          <form>
            <div className={styles.valueTable}>
              <div className={styles.row}>
                <div className={styles.label}>税率 (%)</div>
                <div className={styles.value}><input className={styles.input} name="taxRate" type="number" step="1" value={this.state.taxRate} onChange={this.handleInvoiceChange} onFocus={this.handleFocusSelect} /></div>
              </div>
            </div>
            <div className={styles.valueTable}>
              <div className={styles.row}>
                <div className={styles.label}>軽減税率 (%)</div>
                <div className={styles.value}><input className={styles.input} name="discountedTaxRate" type="number" step="1" value={this.state.discountedTaxRate} onChange={this.handleInvoiceChange} onFocus={this.handleFocusSelect} /></div>
              </div>
            </div>
          </form>
          <form>
            <div className={styles.valueTable}>
              <div className={styles.row}>
                <div className={styles.label}>小計(税抜)</div>
                <div className={`${styles.value} ${styles.currency}`}>{this.formatCurrency(this.calcLineItemsTotal())}</div>
              </div>
              <div className={styles.row}>
                <div className={styles.label}>消費税</div>
                <div className={`${styles.value} ${styles.currency}`}>{this.formatCurrency(this.calcTaxTotal())}</div>
              </div>
              <div className={styles.row}>
                <div className={styles.label}>合計金額</div>
                <div className={`${styles.value} ${styles.currency}`}>{this.formatCurrency(this.calcGrandTotal())}</div>
              </div>
            </div>
          </form>
        </div>

        <div className={styles.pay}>
          <button className={styles.payNow} onClick={this.handlePayButtonClick}>発行</button>
        </div>

        <div className={styles.footer}>
          <div className={styles.comments} style={{ width: '100%' }}>
            <h4>備考</h4>
            <p><a href=""></a>本請求書はインボイス制度によって適用される適格請求書の条件を満たしています。</p>
          </div>
          <div className={styles.closing}>
            <div><input className={styles.input} name="fromCompany" type="string" value={this.state.fromCompany} onChange={this.handleInvoiceChange} onFocus={this.handleFocusSelect} /></div>
          </div>
        </div>

      </div>

    )
  }

}

export default Invoice
